/** @type {string} */
let appPath = "";

/**
 * setAppPath
 * @param {string} value
 * @returns
 */
export const setAppPath = (value) => (appPath = value);

export const getAppPath = () => appPath;

/** @type {Map<string, Object>} */
const mapPaths = new Map();

/**
 * get Image Path
 * @param {string} imgPath
 * @param {string} [initialPath]
 * @param {number} [checkNumber]
 * @param {string} [mapPathName]
 * @returns {Promise<string|null>}
 */
export const getImagePath = async (imgPath, initialPath = "icons", checkNumber = 0, mapPathName) => {
	if (!imgPath) return;

	if (!mapPathName) mapPathName = `getImagePath:${imgPath}-${initialPath}`;

	if (mapPaths.has(mapPathName)) {
		return mapPaths.get(mapPathName);
	}

	const image = new Image();
	let finalPath = imgPath;

	try {
		if (checkNumber == 2) finalPath = finalPath.replace("icons/", "source/").replace("images/", "source/");

		await new Promise((resolve, reject) => {
			if (
				!finalPath?.includes("icons/") &&
				!finalPath?.includes("files/") &&
				!finalPath?.includes("source/") &&
				!imgPath.startsWith("http") &&
				!imgPath.startsWith("www.")
			)
				finalPath = `/${initialPath}/${finalPath}`;

			if (appPath && !finalPath.startsWith(appPath)) finalPath = `${appPath}${finalPath}`;

			image.src = finalPath;

			image.onload = function() {
				resolve();
			};
			image.onerror = () => reject();
		});
	} catch {
		checkNumber++;
		if (checkNumber > 2 || imgPath.trim().startsWith("http") || imgPath.trim().startsWith("www.")) return null;
		if (finalPath?.includes("icons/")) return getImagePath(finalPath.replace("icons/", "files/"), "", checkNumber, mapPathName);
		return getImagePath(finalPath.replace("files/", "icons/"), "", checkNumber);
	}

	if (!mapPaths.has(mapPathName)) mapPaths.set(mapPathName, finalPath);

	return finalPath;
};

/**
 * getImagePathAndSize
 * @param {string} imgPath
 * @param {string} [initialPath]
 * @param {number} [checkNumber]
 * @param {string} [mapPathName]
 * @returns {Promise<{path:string,imgSize:{width:number,height:number}}|null>}
 */
export const getImagePathAndSize = async (imgPath, initialPath = "icons", checkNumber = 0, mapPathName) => {
	if (!imgPath) return;

	if (!mapPathName) mapPathName = `getImagePathAndSize:${imgPath}-${initialPath}`;

	if (mapPaths.has(mapPathName)) {
		return mapPaths.get(mapPathName);
	}

	const image = new Image();
	let finalPath = imgPath;
	let imgSize = { width: 0, height: 0 };

	try {
		if (checkNumber == 2) finalPath = finalPath.replace("icons/", "source/").replace("images/", "source/");

		imgSize = await new Promise((resolve, reject) => {
			if (
				!finalPath?.includes("icons/") &&
				!finalPath?.includes("files/") &&
				!finalPath?.includes("source/") &&
				!imgPath.startsWith("http") &&
				!imgPath.startsWith("www.")
			)
				finalPath = `/${initialPath}/${finalPath}`;

			if (appPath && !finalPath.startsWith(appPath)) finalPath = `${appPath}${finalPath}`;

			image.src = finalPath;

			image.onload = function() {
				// @ts-ignore
				resolve({ width: this.width, height: this.height });
			};
			image.onerror = () => reject();
		});
	} catch {
		checkNumber++;
		if (checkNumber > 2 || imgPath.trim().startsWith("http") || imgPath.trim().startsWith("www.")) return null;
		if (finalPath?.includes("icons/")) return getImagePathAndSize(finalPath.replace("icons/", "files/"), "", checkNumber, mapPathName);
		return getImagePathAndSize(finalPath.replace("files/", "icons/"), "", checkNumber);
	}

	if (!mapPaths.has(mapPathName)) mapPaths.set(mapPathName, { path: finalPath, imgSize });

	return { path: finalPath, imgSize };
};
