import { ref, Ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import { XoneDataCollection } from "./appData/core/XoneDataCollection";
import { XoneDataObject } from "./appData/core/XoneDataObject";
import { XoneApplication } from "../composables/appData/core/XoneApplication";

import { generateUniqueId } from "./helperFunctions/StringHelper";
import xoneUI from "./XoneUI";
import XmlNode from "./appData/Xml/JSONImpl/XmlNode";
import { registerPush, setOnPushReceivedFunction, setOnPushRegisteredFunction, setOnPushRegistrationFailureFunction } from "./Firebase";
import { nextTick } from "vue";
import { xoneAttributesHandler } from "./XoneAttributesHandler";
import { RowInfo } from "./ContentsLoaderHandler";

//
// Add methods to XoneDataObject prototype

XoneDataObject.prototype.changeModelValue = function(propName, newValue) {
	this[propName] = newValue;
};

XoneDataObject.prototype.clone = function() {
	if (this["_ObjCloned"]) return this;

	const newObj = new XoneDataObject(this.getOwnerCollection());
	Object.entries(this).forEach(([key, value]) => (newObj[key] = value));
	newObj["_ObjCloned"] = this;
	newObj["_XoneHashId"] = undefined;
	newObj["_XoneReactive"] = undefined;
	newObj.getObjectIndex = () => this.getObjectIndex();
	return newObj;
};

XoneDataObject.prototype.doModelReactive = function() {
	if (this["_XoneReactive"]) return this;

	this.model = ref(this.model).value;
	this["_XoneReactive"] = true;

	// Si el objeto está clonado, propagamos el cambio
	if (!this["_ObjCloned"] || !this["_ObjCloned"]["_XoneReactive"]) return this;
	Object.keys(this.model).forEach((key) =>
		watch(
			() => this.model[key],
			(newValue) => this["_ObjCloned"].changeModelValue(key, newValue)
		)
	);

	return this;
};

XoneDataObject.prototype.isReactive = function() {
	return this["_XoneReactive"];
};

XoneDataObject.prototype.setCloneOnPush = function(value) {
	this["_CloneOnPush"] = value;
};

XoneDataObject.prototype.getCloneOnPush = function() {
	return this["_CloneOnPush"];
};

XoneDataObject.prototype.getHashId = function(createIfNull = true) {
	if (!this["_XoneHashId"] && createIfNull) this["_XoneHashId"] = Array(3).join(generateUniqueId());
	return this["_XoneHashId"];
};

XoneDataObject.prototype.getParent = function() {
	return this.m_owner.getOwnerObject();
};

XoneDataObject.prototype.executeMethod = async function(method) {
	await xoneAttributesHandler.executeMethod(`executenode(${method})`, this);
};

//
// Add methods to XoneApplication prototipes

XoneApplication.prototype.exit = () => xoneUI.exitApp();

XoneApplication.prototype.exitApp = () => {
	window.close();
	xoneUI.exitApp();
};

XoneApplication.prototype.logout = () => xoneUI.exitApp();

XoneApplication.prototype.pushValueAndExit = (arg) => xoneUI.openEditViewAndExit(arg);

XoneApplication.prototype.registerPush = (callback) => registerPush(callback);

XoneApplication.prototype.bind = (event, callback) => {
	if (event && event.toString().toLowerCase() === "onpushregistered") setOnPushRegisteredFunction(callback);
	if (event && event.toString().toLowerCase() === "onpushregistrationfailure") setOnPushRegistrationFailureFunction(callback);
	if (event && event.toString().toLowerCase() === "onpushreceived") setOnPushReceivedFunction(callback);
};

/**
 * @typedef {Object} Breadcrumb
 * @property {string} id
 * @property {string} xoneHashId
 * @property {string} type
 * @property {string} name
 * @property {string} title
 * @property {boolean} isWebLayout
 * @property {boolean} isMsgbox
 * @property {boolean} isContentsRow
 *
 * Nos da informacion del objeto
 * @typedef {Object} Objectinfo
 * @property {boolean} isContents
 * @property {number} recordIndex
 * @property {boolean} editInRow
 * @property {boolean} autosave
 * @property {function(number): void} onMsgBoxOptionSelected
 * @property {RowInfo} rowInfo
 */

/** Class to handle appData, dataObjectsMap and breadcrumb stack */
class AppDataHandler {
	/**
	 * _instance
	 * @type {AppDataHandler}
	 */
	static _instance;

	/**
	 * appData
	 * @type {XoneApplication}
	 */
	_appData;

	// Separamos los breadcrumb de los dataObjects dado que no se puede hacer un stringify de objetos con estructura circular, así que no lo podemos expandir a los componentes hijo
	// queda con los dataobjects en la propiedad _dataObjectsMap y el indice de breadcrumbs en _breadcrumbs

	/**
	 * dataObjectsMap
	 * @type {Map<string, XoneDataObject>}
	 */
	_dataObjectsMap = new Map();

	/**
	 * breadcrumbs
	 * @type {Ref<Breadcrumb[]>}
	 */
	_breadcrumbs = ref([]);

	constructor() {
		if (AppDataHandler._instance) return AppDataHandler._instance;
		AppDataHandler._instance = this;
	}

	/**
	 * Set AppData
	 * @param {XoneApplication} appData
	 */
	setAppData(appData) {
		this._appData = appData;
	}

	/**
	 * Get AppData
	 * @returns {XoneApplication}
	 */
	getAppData() {
		return this._appData;
	}

	/**
	 * Get breadcrumb
	 * @returns {Breadcrumb[]}
	 */
	getBreadcrumbs() {
		return this._breadcrumbs.value;
	}

	/**
	 * Set DataObject
	 * @param {string} id
	 * @param {XoneDataObject} value
	 */
	addDataObject(id, value) {
		this._dataObjectsMap.set(id, value);
	}

	/**
	 * Get XOneDataObject from breadcrumb id
	 * @param {string} id
	 * @returns {XoneDataObject}
	 */
	getDataObject(id) {
		return this._dataObjectsMap.get(id);
	}

	/**
	 * get XOneDataObjects
	 * @returns {XoneDataObject[]}
	 */
	getDataObjects() {
		return Array.from(this._dataObjectsMap.values());
	}

	/**
	 * Clear dataObjectsMap
	 */
	cleardataObjectsMap() {
		this._dataObjectsMap.clear();
	}

	/**
	 * Clear breadcrumb
	 * @param {boolean} [keepLayout ]
	 */
	clearBreadcrumbs(keepLayout = false) {
		// Remove breadcrumb
		this._breadcrumbs.value = keepLayout ? this._breadcrumbs.value.filter((e) => e.isWebLayout) : [];
		// Remove dataobjects
		this.cleardataObjectsMap();
	}

	/**
	 * Remove breadcrumb from breadcrumb id
	 * @param {string} id
	 */
	removeBreadcrumb(id) {
		const breadcrumb = this._breadcrumbs.value.find((e) => e.id === id);
		if (!breadcrumb) return;

		const index = this._breadcrumbs.value.indexOf(breadcrumb);
		// Remove breadcrumb
		this._breadcrumbs.value.splice(index, 1);
		// Remove dataobject
		this._dataObjectsMap.delete(id);
	}

	/**
	 * delete last breadcrumb
	 */
	deletelastBreadcrumb() {
		if (this._breadcrumbs.value.length === 0) return;
		const lastBreadcrumb = [...this._breadcrumbs.value].reverse()[0];
		this.removeBreadcrumb(lastBreadcrumb.id);
	}

	/**
	 * Clear breadcrumb from key
	 * @param {string} id
	 */
	clearBreadcrumbsFrom(id) {
		const breadcrumb = this._breadcrumbs.value.find((e) => e.id === id);
		if (!breadcrumb) return;
		const index = this._breadcrumbs.value.indexOf(breadcrumb) + 1;
		this._breadcrumbs.value.splice(index);
	}

	/**
	 * clearBreadcrumbsFromXoneHashId
	 * @param {string} xoneHashId
	 */
	clearBreadcrumbsFromXoneHashId(xoneHashId) {
		this._breadcrumbs.value = this._breadcrumbs.value.filter((e) => e.xoneHashId !== xoneHashId);
	}

	/**
	 * Create new XoneDataObject and push it into dataObjectsMap Map
	 * @param {string} collName
	 * @param {string} [objectType]
	 * @param {boolean} [isWebLayout]
	 * @param {boolean} [addObject]
	 * @returns {Promise<string>}
	 */
	async addNewXoneDataObject(collName, objectType = "Coll", isWebLayout = false, addObject = false) {
		/**
		 * Create XoneDataObject
		 * @type {XoneDataCollection}
		 */
		const xoneDataCollection = await this._appData.getCollection(collName);

		if (!xoneDataCollection) return;

		/**
		 * xoneDataObject
		 * @type {XoneDataObject}
		 */
		const xoneDataObject = await xoneDataCollection.CreateObject(true);

		// Para LoginColl, EntryPoint y WebLayout, si son special, se agrega el objeto
		if (addObject && xoneDataCollection.getProperties().getAttrValue("special") === "true") {
			xoneDataCollection.clear();
			xoneDataCollection.addItem(xoneDataObject);
		}

		return this.pushXoneDataObject(xoneDataObject, objectType, isWebLayout);
	}

	/**
	 * Push new XoneDataObject into dataObjectsMap Map
	 * @param {XoneDataObject} xoneDataObject
	 * @param {string} [objectType]
	 * @param {boolean} [isWebLayout]
	 * @returns {string}
	 */
	async pushXoneDataObject(xoneDataObject, objectType = "Coll", isWebLayout = false, isMsgbox = false, isContentsRow = false) {
		if (!isContentsRow && !isMsgbox) {
			xoneUI.showLoader();
			await new Promise((resolve) => setTimeout(() => nextTick().then(() => resolve()), 1));
			setTimeout(() => xoneUI.hideLoader(), 10000);
		}

		const id = generateUniqueId();

		if (xoneDataObject.getCloneOnPush())
			// Si el objeto viene de un contents, tenemos que clonarlo para mantener la reactividad
			xoneDataObject = xoneDataObject.clone();

		/** @type {{m_xmlNode:XmlNode}} */
		const { m_xmlNode } = xoneDataObject.m_owner;

		// Set XOneDataObject
		this.addDataObject(id, xoneDataObject);
		// Push breadcrumb
		this._breadcrumbs.value.push({
			id,
			xoneHashId: xoneDataObject.getHashId(),
			type: objectType,
			name: xoneDataObject.m_owner.m_strName,
			title: m_xmlNode.getAttrValue("title", xoneDataObject.m_owner.m_strName),
			isWebLayout,
			isMsgbox,
			isContentsRow,
		});
		return id;
	}

	/**
	 * setCookie
	 * @param {string} name cookie name
	 * @param {*} value cookie value
	 * @param {number} expiration expiration days
	 */
	setCookie(name, value, expiration) {
		const d = new Date();
		d.setTime(d.getTime() + expiration * 24 * 60 * 60 * 1000);
		const expires = "expires=" + d.toUTCString();
		document.cookie = name + "=" + value + ";" + expires + ";path=/";
	}

	/**
	 * getCookie
	 * @param {string} name cookie name
	 */
	getCookie(name) {
		const cookies = document.cookie.split(";").map((e) => {
			const [name, value] = e.split("=");
			return { name: name.trim(), value: value.trim() };
		});
		return cookies.find((e) => e.name === name)?.value || "";
	}

	/**
	 * removeCookie
	 * @param {string} name
	 */
	removeCookie(name) {
		document.cookie = `${name}= ; expires = ${new Date().toUTCString()};`;
	}

	/**
	 * getCircularReplacer
	 */
	getCircularReplacer() {
		const seen = new WeakSet();
		return (key, value) => {
			if (typeof value === "object" && value !== null) {
				if (seen.has(value)) {
					console.warn("Cache duplicado", key, value);
					return;
				}
				seen.add(value);
			}
			return value;
		};
	}
}

export default new AppDataHandler();
