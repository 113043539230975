<template>
  <div class="tab" ref="groupsElement" :style="{
        // BackgroundColor
        backgroundColor: attributes.tabBgColor}">
    <button
      :class="[
        'tablinks',
        activeGroup === group.attributes.id && 'activeGroup',
      ]"
      v-for="group in groups"
      :key="`grp-btn-${group.attributes.id}`"
      @click="changeGroup(group.attributes.id)"
      :style="{
        // Variables
        '--text-color': attributes.tabForecolor,
        '--selected-text-color': attributes.tabSelectedForecolor,
        // Padding
        paddingTop: attributes.tabPaddings.top,
        paddingRight: attributes.tabPaddings.right,
        paddingBottom: attributes.tabPaddings.bottom,
        paddingLeft: attributes.tabPaddings.left,
        // FontSize
        fontSize: attributes.tabFontSize,
      }"
    >
      {{ group.attributes.name }}
    </button>
  </div>
</template>

<script>
import { inject, nextTick, ref, Ref, watch } from "vue";

export default {
  name: "Group",

  props: {
    attributes: { type: Object, required: true },
    groups: {
      type: Array,
      default: new Array(),
    },
    containerHeight: { type: Number, default: 0 },
  },

  emits: ["onHeightChanged"],

  setup(props, context) {
    /** @type {{activeGroup: Ref<string>, changeGroup: Function}} */
    const { activeGroup, changeGroup } = inject("groupHandler");

    /** @type {Ref<HTMLElement>} */
    const groupsElement = ref();

    nextTick(() =>
      context.emit("onHeightChanged", groupsElement.value?.clientHeight || 0)
    );

    watch(
      () => props.containerHeight,
      async () => {
        await nextTick();
        context.emit("onHeightChanged", groupsElement.value?.clientHeight || 0);
      }
    );

    return { activeGroup, changeGroup, groupsElement };
  },
};
</script>

<style scoped>
/* Style the tab */
.tab {
  overflow: hidden;
  /* border: 1px solid #ccc; */
  border-top: none;
  border-right: none;
  border-left: none;
  display: flex;
  overflow-x: auto;
  flex-shrink: 0;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  color: var(--text-color);
  border: none;
  outline: none;
  cursor: pointer;
  padding: 7px 8px;
  transition: 0.3s;
  font-size: 17px;
  flex-shrink: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/* Change background color of buttons on hover */
.tab button:hover {
  color: black;
  /* border-bottom: 1px solid black; */
}

/* Create an activeGroup/current tablink class */
.tab button.activeGroup {
  color: var(--selected-text-color);
  /* border-bottom: 1px solid var(--selected-text-color); */
}
</style>