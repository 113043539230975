/**
 * generate Unique Id
 * @returns {string}
 */
export const generateUniqueId = () =>
	Math.random()
		.toString(36)
		.substr(2, 9);

export const generateUniqueDeviceId = () => {
	return `${Math.random()
		.toString(36)
		.substr(2, 9)}-${Math.random()
		.toString(36)
		.substr(2, 9)}-${Math.random()
		.toString(36)
		.substr(2, 9)}-${Math.random()
		.toString(36)
		.substr(2, 9)}`;
};

/**
 * generate a Document from xml string
 * @param {string} xml
 * @returns {Document}
 */
export const parseXoneXml = (xml) => new DOMParser().parseFromString(xml, "text/xml");

const isEmpty = (x) => typeof x == "undefined" || x.length == 0 || x == null;

const capComment = 1,
	capSelector = 2,
	capEnd = 3,
	capAttr = 4;
const commentX = /\/\*[\s\S]*?\*\//g;
const lineAttrX = /([^\:]+):([^\;]*);/;
const altX = /(\/\*[\s\S]*?\*\/)|([^\s\;\{\}][^\;\{\}]*(?=\{))|(\})|([^\;\{\}]+\;(?!\s*\*\/))/gim;

/**
 * Input is css string and current pos, returns JSON object
 * @param {string} cssString
 * @param {object} [args]
 */
export const parseXoneCss = (
	cssString,
	args = {
		comments: false,
		stripComments: true,
	}
) => {
	const node = {};

	let match = null;
	let count = 0;

	if (args.stripComments) {
		args.comments = false;
		cssString = cssString.replace(commentX, "");
	}
	while ((match = altX.exec(cssString))) {
		if (!isEmpty(match[capComment]) && args.comments) {
			// Comment
			node[count++] = match[capComment].trim();
		} else if (!isEmpty(match[capSelector])) {
			// New node, we recurse
			const name = match[capSelector].trim();
			// This will return when we encounter a closing brace
			const newNode = parseXoneCss(cssString, args);

			[name]
				.map((e) => e?.trim())
				.forEach((sel) => {
					if (sel in node) {
						newNode.attributes?.forEach((att) => (node[sel][att] = newNode.attributes[att]));
					} else {
						node[sel] = newNode;
					}
				});
		} else if (!isEmpty(match[capEnd])) {
			// Node has finished
			return node;
		} else if (!isEmpty(match[capAttr])) {
			const line = match[capAttr].trim();
			const attr = lineAttrX.exec(line);
			if (!attr) continue;
			// Attribute
			const name = attr[1].trim();
			const value = attr[2].trim();
			node[name] = value;
		}
	}

	return node;
};
