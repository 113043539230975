<template>
	<div
		ref="containerElement"
		v-show="isActive && !isDisableVisible"
		:group="attributes.node === 'group' || null"
		:class="['xone-frame', attributes.keepAspectRatio && 'keep-aspect-ratio', attributes.framebox && 'xone-frame-borders']"
		:xone-name="attributes.name"
		:style="{
			// Variables
			'--border-width': attributes.borderWidth,
			'--border-color': attributes.borderColor,
			'--elevation': `${attributes.elevation}px`,
			// Margins
			marginTop: margins.top + 'px',
			marginRight: margins.right + 'px',
			marginBottom: margins.bottom + 'px',
			marginLeft: margins.left + 'px',
			// Paddings
			paddingTop: paddings.top + 'px',
			paddingRight: paddings.right + 'px',
			paddingBottom: paddings.bottom + 'px',
			paddingLeft: paddings.left + 'px',
			// Size
			maxWidth: !isContents && attributes.node === 'group' && containerWidth ? `${containerWidth}px` : null,
			maxHeight: !isContents && attributes.node === 'group' && containerHeight ? `${containerHeight}px` : null,
			width: (attributes.node === 'group' && !attributes.drawerOrientation && '100%') || (controlWidth && `${controlWidth}px`) || 'auto',
			height:
				(attributes.node === 'group' && !attributes.drawerOrientation && !attributes.fixed && '100%') ||
				(controlHeight && (attributes.node !== 'group' || attributes.fixed) && `${controlHeight}px`) ||
				'auto',
			flexGrow: attributes.node === 'frame' && attributes.width === 'grow' ? 1 : null,
			flexShrink: attributes.node === 'frame' && attributes.width === 'grow' ? 1 : null,
			// Background
			backgroundColor: displayScreenThresholds ? '#00ff0025' : attributes.bgColor,
			backgroundImage: attributes.image && `url(${appPath}/icons/${attributes.image})`,
			// Border
			border: displayScreenThresholds && `1px solid ${attributes.borderColor}`,
			borderRadius: attributes.borderCornerRadius,
			// Align
			justifyContent: attributes.align.column,
			// Scrolls
			overflowY:
				attributes.node === 'frame' && attributes.width === 'grow'
					? 'hidden'
					: // Esto en android no permite el scroll en un grupo, pero yo lo voy a dejar libre porque me parece que no tiene sentido -> //attributes.node === 'group' ? 'hidden' :
					attributes.scroll && attributes.scrollOrientation === 'vertical'
					? 'auto'
					: 'hidden',
			overflowX:
				attributes.node === 'frame' && attributes.width === 'grow'
					? 'hidden'
					: attributes.scroll && attributes.scrollOrientation === 'horizontal'
					? 'auto'
					: 'hidden',
			// Floating
			position: attributes.floating.floating && 'absolute',
			zIndex: attributes.floating.floating && '9',
			top: attributes.floating.floating && floatingTop && floatingTop,
			left: attributes.floating.floating && floatingLeft && floatingLeft,
			// Designer
			cursor: isDesigner && isDragging && 'move', //|| (attributes.onClick && 'pointer'),
		}"
		@scroll="onScroll"
		@click="onClick($event)"
		@mousedown="isDesigner && onMouseDown($event)"
		@mouseenter="isDesigner && onMouseEnter($event)"
		@mouseleave="isDesigner && onMouseLeave($event)"
		@mousemove="isDesigner && onDragOver($event)"
		@touchmove="isDesigner && onDragOver($event)"
	>
		<!-- Rows of the container -->
		<template v-if="attributes.node === 'group' || !isDisableVisible">
			<Row
				v-for="(row, irows) in rows"
				:xone-parent="attributes.name"
				:key="`${attributes.name}-${irows}`"
				:style="{
					// Align
					justifyContent: attributes.align.row,
					alignItems: attributes.align.column,
					flexWrap: attributes.wrap ? 'wrap' : null,
				}"
			>
				<!-- Columns of the Row -->
				<Column
					v-for="(column, iColumns) in row"
					:key="`${column.attributes.name}-${iColumns}`"
					:attributes="column.attributes"
					:xoneDataObject="xoneDataObject"
				>
					<!-- Container -->
					<Container
						v-if="column.attributes.node === 'frame' || column.attributes.node === 'group'"
						:xoneDataObject="xoneDataObject"
						:control="column"
						:containerHeight="controlHeight || controlGrowHeight || containerHeight"
						:containerWidth="controlWidth || controlGrowWidth || containerWidth"
					></Container>
					<!-- Prop -->
					<Prop
						v-if="column.attributes.node === 'prop'"
						:xoneDataObject="xoneDataObject"
						:control="column"
						:containerHeight="controlHeight || controlGrowHeight || containerHeight"
						:containerWidth="controlWidth || controlGrowWidth || containerWidth"
					></Prop>
				</Column>
			</Row>
		</template>
	</div>
</template>

<script>
import { computed, inject, onMounted, ref, Ref, ComputedRef, watch, provide, nextTick, PropType, onUnmounted } from "vue";
import Row from "@/components/Row";
import Column from "@/components/Column";
import Prop from "@/components/Prop";
import { xoneAttributesHandler, ContainerAttributes, Margins } from "../composables/XoneAttributesHandler";
import { XoneDataObject } from "../composables/appData/core/XoneDataObject";
import { XoneControl, XoneView } from "../composables/XoneViewsHandler";
import dragAndDrop from "../composables/DragAndDrop";
import AppDataHandler, { Objectinfo } from "../composables/AppDataHandler";
import { getAppPath } from "../composables/helperFunctions/ImageHelper";

export default {
	name: "Container",
	components: { Row, Column, Prop },
	props: {
		/**
		 * xoneDataObject
		 * @type {PropType<XoneDataObject>}
		 */
		xoneDataObject: { type: Object, required: true },
		control: { type: Object, required: true },
		containerWidth: { type: Number, default: 0 },
		containerHeight: { type: Number, default: 0 },
		visibilityBit: { type: Number, default: 1 },
	},
	emits: ["onDataObjectChange"],
	/**
	 * Group / Frame control
	 */
	setup(props, context) {
		const displayScreenThresholds = inject("displayScreenThresholds");

		/**
		 * objectInfo
		 * @type {Objectinfo}
		 */
		const objectInfo = inject("objectInfo");
		const { isContents } = objectInfo;

		// Si es un contents y es edit-inrow, hacemos el objeto reactivo y lo creamos en el stack de objetos como una vista, por si se desea coger la vista del objeto
		if (objectInfo.editInRow && props.control.attributes.node === "group") {
			// Clonamos el objeto para que no pierda su reactividad
			const newObj = props.xoneDataObject.clone().doModelReactive();
			// Hacemos push del objeto en el stack de objetos
			const contentsRowbreadcrumbId = AppDataHandler.pushXoneDataObject(newObj, "contentsRow", false, false, true);
			// Creamos y proveemos la vista
			const newXoneView = new XoneView(newObj);
			provide("xoneView", newXoneView);
			// Destruimos el objeto del stack de objetos

			onUnmounted(() => AppDataHandler.removeBreadcrumb(contentsRowbreadcrumbId));
			// Emitimos el cambio de objeto para setearlo en el contents
			context.emit("onDataObjectChange", newObj);
		}

		/**
		 * Attributes model
		 * @type {ComputedRef<ContainerAttributes>}
		 */
		const attributesModel = computed(() => xoneAttributesHandler.getContainerAttributes(props.control.attributes));

		/**
		 * Reactive Attributes
		 * @type {Ref<ContainerAttributes>}
		 */
		const attributes = ref({ ...attributesModel.value });

		// Observe changes in attributes model and data model with ##FLD_ fields and fetch them to attributes
		xoneAttributesHandler.watchAttributes(attributes, attributesModel, props.xoneDataObject.model);

		// Provide group id
		if (attributes.value.node === "group") provide("groupId", attributes.value.id);

		/**
		 * Check if disablevisible
		 * @type {ComputedRef<boolean>}
		 */
		const isDisableVisible = computed(() => {
			// Frame without children
			if (attributes.value.node === "frame" && props.control.controls?.length === 0) return true;
			// No disablevisible attribute
			if ((attributes.value.disableVisible ?? "") === "") return false;
			// Eval disablevisible formula
			return xoneAttributesHandler.evalFormula(attributes.value.disableVisible, props.xoneDataObject?.model);
		});

		/**
		 * Group active
		 * @type {Ref<string>}
		 */
		const { activeGroup } = inject("groupHandler");

		/**
		 * Is current group active
		 * @type {ComputedRef<boolean>}
		 */
		const isActive = computed(() => {
			return (
				attributes.value.node !== "group" ||
				attributes.value.fixed ||
				attributes.value.id === activeGroup.value ||
				attributes.value.drawerOrientation ||
				props.visibilityBit === 4
			);
		});

		// Execute onfocus method
		if (attributes.value.node === "group" && attributes.value.onFocus)
			watch(
				() => activeGroup.value,
				async (newValue) => {
					if (newValue !== attributes.value.id) return;
					await xoneAttributesHandler.executeMethod(attributes.value.onFocus, props.xoneDataObject);
				}
			);

		/**
		 * Container rows / columns
		 * @type {ComputedRef<Array>}
		 */
		const rows = computed(() => {
			const validNodes = ["group", "frame", "prop"];
			let rowsArr = [];
			let row = [];
			props.control.controls
				?.filter((e) => validNodes.includes(e.attributes.node))
				?.forEach((e) => {
					// Set width and height = 100% if group has only 1 child and it's a frame
					if (
						attributes.value.node === "group" &&
						props.control.controls.length === 1 &&
						e.attributes.node === "frame" &&
						!attributes.value.fixed &&
						!attributes.value.floating?.floating &&
						!attributes.value.floating?.drawerOrientation
					) {
						if (!objectInfo.isMsgBox && !isContents) {
							e.attributes.height = "100%";
							e.attributes.width = "100%";
						}
					}
					const controlAttributes = xoneAttributesHandler.getContainerAttributes(e.attributes);
					// newline true
					if (controlAttributes.newLine) {
						if (row.length !== 0) {
							rowsArr.push(row);
							row = [];
						}
						row.push(e);
					}
					// newline false
					else {
						row.push(e);
					}
				});
			rowsArr.push(row);

			// Exclude empty containers
			/** @type {boolean} */
			let clear;
			const clearEmptyContainers = (control, /** @type {[]]} */ parent) => {
				if (
					control.attributes.node === "frame" &&
					(!control.controls || control.controls.filter((e) => validNodes.includes(e.attributes.node)).length === 0)
				) {
					parent.splice(parent.indexOf(control), 1);
					clear = true;
				}
				control.controls?.forEach((e) => clearEmptyContainers(e, control.controls));
			};
			do {
				clear = false;
				rowsArr.forEach((row) => row.forEach((column) => clearEmptyContainers(column, row)));
			} while (clear);

			return rowsArr;
		});

		const isMouseOver = ref(false);

		/**
		 * Scale Factor
		 * @type {{widthFactor: ComputedRef<number>, heightFactor: ComputedRef<number>}}
		 */
		const { widthFactor, heightFactor } = inject("scaleFactor");

		const controlGrowWidth = ref(null),
			controlGrowHeight = ref(null);

		//
		// Width|Height = -1; 'grow', the container occuppies all available size
		onMounted(() => {
			if (isContents) objectInfo.rowInfo.setIsLoaded(true);
			// Set flex-grow to row container -> Height Grow
			if (attributes.value.height === "grow") {
				const { parentElement } = containerElement.value;
				if (parentElement.classList.contains("xone-row")) {
					parentElement.style.flexGrow = 1;
					parentElement.style.flexShrink = 1;
					parentElement.style.overflow = "hidden";
					const observerHeight = new ResizeObserver(() => {
						controlGrowHeight.value = parentElement.clientHeight - (margins.value?.top || 0) - (margins.value?.bottom || 0);
					});
					observerHeight.observe(parentElement);
					onUnmounted(() => observerHeight?.disconnect());
				}
			}

			if (attributes.value.width === "grow" || attributes.value.align.column === "center") {
				const observerWidth = new ResizeObserver(() => {
					if (attributes.value.width === "grow") {
						controlGrowWidth.value = containerElement.value.clientWidth - (margins.value?.left || 0) - (margins.value?.right || 0);
					}
					fixJustifyCenter();
				});
				observerWidth.observe(containerElement.value);
				onUnmounted(() => observerWidth?.disconnect());
			}
		});

		/**
		 * Calculate width of the current container
		 * @type {ComputedRef<number>}
		 */
		const controlWidth = computed(() => {
			let width = xoneAttributesHandler.getScaledSize(attributes.value.width, props.containerWidth, widthFactor.value);
			return !width ? null : isMouseOver.value ? width - 4 : width;
		});

		/**
		 * Calculate height of the current container
		 * @type {ComputedRef<number>}
		 */
		const controlHeight = computed(() => {
			const height = xoneAttributesHandler.getScaledSize(attributes.value.height, props.containerHeight, heightFactor.value);
			if (!height && controlGrowHeight.value) return controlGrowHeight.value;
			return !height ? null : isMouseOver.value ? height - 4 : height;
		});

		/**
		 * Margins of the current container
		 * @type {ComputedRef<Margins>}
		 */
		const margins = computed(() => ({
			top: xoneAttributesHandler.getScaledSize(attributes.value.margins.top, props.containerHeight, heightFactor.value),
			left: xoneAttributesHandler.getScaledSize(attributes.value.margins.left, props.containerWidth, widthFactor.value),
			bottom: xoneAttributesHandler.getScaledSize(attributes.value.margins.bottom, props.containerHeight, heightFactor.value),
			right: xoneAttributesHandler.getScaledSize(attributes.value.margins.right, props.containerWidth, widthFactor.value),
		}));

		/**
		 * Margins of the current container
		 * @type {ComputedRef<Margins>}
		 */
		const paddings = computed(() => ({
			top: xoneAttributesHandler.getScaledSize(attributes.value.paddings.top, props.containerHeight, heightFactor.value),
			left: xoneAttributesHandler.getScaledSize(attributes.value.paddings.left, props.containerWidth, widthFactor.value),
			bottom: xoneAttributesHandler.getScaledSize(attributes.value.paddings.bottom, props.containerHeight, heightFactor.value),
			right: xoneAttributesHandler.getScaledSize(attributes.value.paddings.right, props.containerWidth, widthFactor.value),
		}));

		/**
		 * calculate floating top
		 * @type {ComputedRef<string>}
		 */
		const floatingTop = computed(() => xoneAttributesHandler.getScaledPosition(attributes.value.floating.top, heightFactor.value));

		/**
		 * calculate floating leeft
		 * @type {ComputedRef<string>}
		 */
		const floatingLeft = computed(() => xoneAttributesHandler.getScaledPosition(attributes.value.floating.left, widthFactor.value));

		//
		// Handler refresh
		/**
		 * xoneView
		 * @type {XoneView}
		 */
		const xoneView = inject("xoneView");

		onMounted(() => {
			// Create XoneControl
			const xoneControl = new XoneControl(attributes.value.name, containerElement.value);
			// refresh method
			xoneControl.refresh = () =>
				props.control.controls.forEach((e) => {
					if (xoneView[e.attributes.name]?.refresh) xoneView[e.attributes.name].refresh();
				});
			// scrollBy method
			xoneControl.scrollBy = (value) => {
				if (!containerElement.value) return;
				value = value.toString();
				const { scrollHeight } = containerElement.value;
				if (value.includes("%"))
					return containerElement.value.scrollTo({
						top: Number((value.replace("%", "") * scrollHeight) / 100),
						behavior: "smooth",
					});
				containerElement.value.scrollTo({
					top: Number(value.replace("p", "") * heightFactor.value),
					behavior: "smooth",
				});
			};
			// scrollTo method
			xoneControl.scrollTo = xoneControl.scrollBy;
			// scrollToTop method
			xoneControl.scrollToTop = () => xoneControl.scrollBy(0);
			// scrollToBottom method
			xoneControl.scrollToBottom = () => xoneControl.scrollBy(containerElement.value?.scrollHeight);
			// Add control to view
			xoneView.addControl(xoneControl);

			// AnimationIn
			// Load animation once control is loaded
			if (attributes.value.animationIn)
				watch(
					() => isDisableVisible.value,
					(value) => {
						if (!value && !containerElement.value.style.animation) containerElement.value.style.animation = attributes.value.animationIn;
					}
				);
		});

		/**
		 * containerElement
		 * @type {Ref<HTMLElement>}
		 */
		const containerElement = ref();

		// Flex no se comporta bien haciendo un justify-center center cuando tiene scroll, compruebo si lo tiene y se lo quito en tal caso
		const fixJustifyCenter = () => {
			if (attributes.value.align.column === "center" && containerElement.value.offsetHeight < containerElement.value.scrollHeight)
				containerElement.value.style.justifyContent = null;
		};

		nextTick(() => fixJustifyCenter());

		watch(
			() => props.containerHeight,
			() => fixJustifyCenter()
		);

		//
		// onScroll event
		let scrollTimeout;
		const onScroll = () => {
			if (scrollTimeout) clearTimeout(scrollTimeout);
			scrollTimeout = setTimeout(() => xoneAttributesHandler.onScrollEvent(containerElement.value, attributes.value, props.xoneDataObject), 250);
		};
		nextTick(() => onScroll());

		//
		// on click button
		let isExecutingOnClick = false;
		const onClick = async (/** @type {PointerEvent} */ e) => {
			return;
			if (isExecutingOnClick || !attributes.value.onClick) return;
			e.stopPropagation();
			isExecutingOnClick = true;
			await xoneAttributesHandler.onClick(attributes.value, props.xoneDataObject, objectInfo);
			isExecutingOnClick = false;
		};

		return {
			containerElement,
			displayScreenThresholds,
			attributes,
			isActive,
			rows,
			isMouseOver,
			controlWidth,
			controlHeight,
			margins,
			paddings,
			isDisableVisible,
			floatingTop,
			floatingLeft,
			isDesigner: dragAndDrop.getIsDesigner(),
			isDragging: dragAndDrop.getIsDragging(),
			// isDesignerPropSelected: computed(
			//   () => dragAndDrop.getSelectedControl()?.value === containerElement.value
			// ),
			borderDesignSelected: dragAndDrop.getBorderSelected(),
			borderDesignOver: dragAndDrop.getBorderContainerOver(),
			controlGrowWidth,
			controlGrowHeight,
			onScroll,
			onClick,
			appPath: getAppPath(),
			isContents,
			//
			// Designer mode Drag & Drop
			onMouseEnter: () => dragAndDrop.mouseEnter(containerElement.value),
			onMouseLeave: () => dragAndDrop.mouseLeave(containerElement.value),
			onMouseDown: (e) => dragAndDrop.mouseDown(e),
			onDragOver: (e) => dragAndDrop.mouseMove(e),
		};
	},
};
</script>
<style scoped>
.xone-frame {
	display: flex;
	flex-direction: column;
	align-self: flex-start;
	background-repeat: no-repeat;
	background-position: center;
	/* background-size: contain; */
	background-size: 100% 100%;
	overflow-x: hidden;
	overflow-y: auto;
	/* transition: all 0.2s; */
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;

	box-shadow: 0px 0px var(--elevation) 0 rgba(0, 0, 0, 0.2);

	outline: var(--outine-border);
	outline-offset: var(--selected-offset);
}

.xone-frame-borders {
	border: var(--border-width) solid var(--border-color);
}

.keep-aspect-ratio {
	background-size: contain;
}

.tabcontent {
	height: auto;
	width: auto;
	flex-shrink: 1;
	flex-grow: 1;
}
</style>
