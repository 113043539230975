<template>
	<!-- 
  ----
  Fixed Top 
  ----
  -->
	<div ref="fixedTopElement" :id="`fixed-top-${breadcrumb.id}`" class="xone-fixed" style="display: bock" v-if="groups.fixedTop.length !== 0">
		<Container
			v-for="fixed in groups.fixedTop"
			:key="`fixed-top-${fixed.attributes.name}`"
			:xoneDataObject="xoneDataObject"
			:control="fixed"
			:containerWidth="containerWidth"
			:containerHeight="containerHeight"
		></Container>
	</div>
	<!-- 
  ----
  Groups
  ----
  -->
	<Groups v-if="!attributes.noTab" :attributes="attributes" :groups="groups.tabs" @onHeightChanged="onGroupsHeaderHeightChanged"></Groups>

	<div
		class="xone-groups-content-box"
		:style="{
			//position: groups.drawerLeft.length !== 0 ? 'absolute' : '',
			maxHeight: contentElementHeight && `${contentElementHeight}px`,
			overflow: drawers.overflow,
		}"
	>
		<!-- 
    ----
    Groups content
    ----
    -->
		<div
			ref="contentElement"
			class="xone-group"
			:style="{
				marginLeft: null,
				maxHeight: contentElementHeight && `${contentElementHeight - groupsHeaderHeight}px`,
			}"
		>
			<Container
				:class="('tabcontent', groupAnimation)"
				v-for="group in groups.tabs"
				:key="`group-${group.attributes.name}`"
				:xoneDataObject="xoneDataObject"
				:control="group"
				:containerWidth="containerWidth"
				:containerHeight="contentElementHeight - groupsHeaderHeight"
			></Container>
		</div>
		<!-- 
    ----
    Drawers
    ----
    -->
		<!-- Close Drawers -->
		<div
			class="xone-close-drawer"
			:class="[drawers.showLeft || drawers.showRight ? 'fade-in' : 'fade-out']"
			v-if="drawers.showLeft || drawers.showRight"
			@click="drawers.closeDrawers()"
		></div>
		<!-- Drawer Left -->
		<Container
			v-if="groups.drawerLeft.length !== 0"
			:id="`xone-sidenav-left-${breadcrumb.id}`"
			class="xone-sidenav-left"
			:xoneDataObject="xoneDataObject"
			:control="groups.drawerLeft[0]"
			:containerWidth="containerWidth"
			:containerHeight="containerHeight"
			:style="{
				opacity: drawers.showLeft ? 1 : 0,
				marginLeft: `${drawers.showLeft ? 0 : -drawers.leftWidth}px`,
				pointerEvents: drawers.showLeft ? 'all' : 'none',
			}"
		></Container>
		<!-- Drawer Right -->
		<Container
			v-if="groups.drawerRight.length !== 0"
			:id="`xone-sidenav-right-${breadcrumb.id}`"
			class="xone-sidenav-right"
			:xoneDataObject="xoneDataObject"
			:control="groups.drawerRight[0]"
			:containerWidth="containerWidth"
			:containerHeight="containerHeight"
			:style="{
				opacity: drawers.showRight ? 1 : 0,
				marginRight: `${drawers.showRight ? 0 : -drawers.rightWidth}px`,
				pointerEvents: drawers.showRight ? 'all' : 'none',
			}"
		></Container>
	</div>
	<!--
  ----
  Fixed bottom
  ----
  -->
	<div ref="fixedBottomElement" :id="`fixed-bottom-${breadcrumb.id}`" class="xone-fixed" v-if="groups.fixedBottom.length !== 0">
		<Container
			v-for="fixed in groups.fixedBottom"
			:key="`fixed-bottom-${fixed.attributes.name}`"
			:xoneDataObject="xoneDataObject"
			:control="fixed"
			:containerWidth="containerWidth"
			:containerHeight="containerHeight"
		></Container>
	</div>
</template>

<script>
// vue
import { computed, inject, onBeforeMount, onMounted, onUnmounted, provide, reactive, ref, Ref, watchEffect, ComputedRef, watch, PropType } from "vue";
// components
import Groups from "@/components/Groups";
// composables
import { SwipeHandler } from "../composables/SwipeHandler";
import AppDataHandler, { Breadcrumb, Objectinfo } from "../composables/AppDataHandler";
import {
	getCollGroups,
	showGroup,
	slideGroup,
	getDrawersWidth,
	watchContentElementSize,
	CollGroups,
} from "../composables/helperFunctions/GroupsHelper";
import { xoneAttributesHandler, ContainerAttributes } from "../composables/XoneAttributesHandler";
import xoneUI, { setShowGroupCallback, setXoneDataObject } from "../composables/XoneUI";
import { XoneDataObject } from "../composables/appData/core/XoneDataObject";
import { XoneView } from "../composables/XoneViewsHandler";

export default {
	props: {
		breadcrumb: {
			type: Object,
			default: null,
		},
		isLayout: { type: Boolean, default: false },
		/** @type {PropType<Objectinfo>} */
		objectInfo: { type: Object, default: null },
	},
	components: {
		Groups,
	},
	setup(props) {
		/**
		 * Get XoneDataObject
		 * @type {XoneDataObject}
		 */
		const xoneDataObject = AppDataHandler.getDataObject(props.breadcrumb.id);

		// Do Model Reactive
		xoneDataObject.doModelReactive();

		/**
		 * Last breadcrumb in stack
		 * @type {ComputedRef<Breadcrumb>}
		 */
		const lastBreadcrumb = inject("lastBreadcrumb");

		// Provide breadcrumbId info to child components
		provide("breadcrumbId", props.breadcrumb.id);

		/**
		 * Layout model
		 * @type {Ref<Object>}
		 */
		const layout = ref();

		/**
		 * Viewport size conditions sm / md / lg
		 * @type {Ref<string>}
		 */
		const sizeConditions = inject("sizeConditions");

		/**
		 * orientation vertical / horizontal
		 * @type {Ref<string>}
		 */
		const orientation = inject("orientation");

		const getLayoutAsync = async () => {
			// Get Layout
			layout.value = await xoneDataObject.getLayoutAsync();
			xoneDataObject.setLayout(layout.value);

			//
			// Execute onvisualconditionschange
			xoneDataObject.ExecuteNode("onvisualconditionschange", sizeConditions.value, orientation.value).catch(console.error);
		};

		layout.value = xoneDataObject.getLayout();
		xoneDataObject.setLayout(layout.value);

		// Change size Conditions
		watch(
			() => sizeConditions.value,
			() => getLayoutAsync()
		);

		// watch(
		// 	() => orientation.value,
		// 	() => getLayoutAsync()
		// );

		/**
		 * Collection attributes
		 * @type {ComputedRef<ContainerAttributes>}
		 */
		const attributes = computed(() => xoneAttributesHandler.getContainerAttributes(layout.value.attributes));

		const isBeforeEditExecuted = ref(false);
		provide("isBeforeEditExecuted", isBeforeEditExecuted);

		// Execute node before-edit
		onBeforeMount(() =>
			xoneDataObject
				.ExecuteNode("before-edit")
				.catch(console.error)
				.finally(() => (isBeforeEditExecuted.value = true))
		);

		// Execute node after-edit
		onMounted(async () => {
			xoneUI.hideLoader();
			while (!isBeforeEditExecuted.value) await new Promise((resolve) => setTimeout(() => resolve(), 5));
			xoneDataObject.ExecuteNode("after-edit").catch(console.error);
		});

		//
		// Manage coll groups

		/**
		 * Groups
		 * @type {ComputedRef<CollGroups>}
		 */
		const groups = computed(() => getCollGroups(layout.value?.controls));

		/**
		 * Active Group
		 * @type {Ref<string>}
		 */
		const activeGroup = ref(groups.value.tabs.length !== 0 && groups.value.tabs[0].attributes.id);

		const changeGroup = (id, isToggle = false, isHide = false) => showGroup(id, groups.value, drawers, activeGroup, groupAnimation, isToggle, isHide);

		/**
		 * Window Size
		 * @type {{containerWidth: Ref<number>|ComputedRef<number>, containerHeight: Ref<number>|ComputedRef<number>}}
		 */
		const { containerWidth, containerHeight } = inject("containerSize");

		/**
		 * Group tab content element
		 * @type {Ref<number>}
		 */
		const contentElementHeight = ref(containerWidth.value);

		const fixedTopElement = ref();
		const fixedBottomElement = ref();

		// Watch contents element height changes
		watchContentElementSize(contentElementHeight, containerHeight, containerWidth, fixedTopElement, fixedBottomElement, props.breadcrumb.id);

		/**
		 * xone View
		 * @type {XoneView}
		 */
		const xoneView = new XoneView(xoneDataObject);
		xoneView.showGroup = (id) => changeGroup(id);
		xoneView.hideGroup = (id) => changeGroup(id);
		xoneView.toggleGroup = (id) => changeGroup(id, true);

		provide("xoneView", xoneView);

		// Current XoneDataObject focused
		watchEffect(() => {
			if (props.breadcrumb.id === lastBreadcrumb.value?.id) {
				// Send changeGroup to XoneUI showGroup method
				setShowGroupCallback(changeGroup);
				// Send refresh items
				setXoneDataObject(xoneDataObject);
			}
		});

		// Provide groupHandler to child components
		provide("groupHandler", {
			// active group
			activeGroup: activeGroup,
			// change group callback function
			changeGroup,
		});

		/**
		 * Group transition animation
		 * @type {Ref<string>}
		 */
		const groupAnimation = ref("");

		/**
		 * contentElement
		 * @type {Ref<HTMLElement>}
		 */
		const contentElement = ref();
		/**
		 * Change current group by swipe
		 * @type {SwipeHandler}
		 */
		let swipeHandler;
		// Initialize group swipe handler
		onMounted(() => {
			if (!attributes.value.groupSwipe) return;
			swipeHandler = new SwipeHandler(
				// swipe group callback function
				(step) => slideGroup(step, groups.value, activeGroup, groupAnimation)
			);
			swipeHandler.init(contentElement.value);
		});
		// clear group swipe handler
		onUnmounted(() => swipeHandler?.clear());

		/**
		 * drawers
		 */
		const drawers = reactive({
			leftWidth: 0,
			rightWidth: 0,
			showLeft: false,
			showRight: false,
			overflow: "hidden",
			closeDrawers: () => {
				drawers.showLeft = false;
				drawers.showRight = false;
			},
			currentWindowWidth: containerWidth.value,
		});

		// Get drawers width
		onMounted(() => getDrawersWidth(drawers, containerWidth, props.breadcrumb.id));

		// Provide object info
		provide("objectInfo", props.objectInfo || { isContents: false });

		const groupsHeaderHeight = ref(0);

		const onGroupsHeaderHeightChanged = (value) => (groupsHeaderHeight.value = value);

		return {
			attributes,
			groups,
			drawers,
			groupAnimation,
			containerWidth,
			containerHeight,
			xoneDataObject,
			fixedTopElement,
			fixedBottomElement,
			contentElementHeight,
			contentElement,
			onGroupsHeaderHeightChanged,
			groupsHeaderHeight,
		};
	},
};
</script>

<style scoped>
.xone-fixed {
	display: block;
}

.xone-groups-content-box {
	width: var(--app-width);
	position: relative;
	display: flex;
	/* overflow: hidden; */
	overflow: hidden;
	flex-grow: 1;
	flex-shrink: 1;
}

.xone-close-drawer {
	z-index: 999;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: black;
	pointer-events: all;
}

.fade-in {
	animation: fadeIn05 0.3s;
	opacity: 0.5;
}

.fade-out {
	animation: fadeOut05 0.3s;
	opacity: 0;
}

.xone-group {
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	flex-grow: 1;
	overflow: hidden;
	height: auto;
	transition: opacity 0s, margin-left 0.3s, margin-right 0.3s;
}

.xone-sidenav-left {
	z-index: 999;
	position: absolute;
	flex-shrink: 0;
	top: auto;
	left: 0;
	width: auto;
	pointer-events: all;
	opacity: 0;
	transition: all 0.3s;
	/* box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2), 0 0px 10px rgba(0, 0, 0, 0.24); */
}

.xone-sidenav-right {
	z-index: 999;
	position: absolute;
	flex-shrink: 0;
	top: auto;
	right: 0;
	width: auto;
	pointer-events: all;
	opacity: 0;
	transition: all 0.3s;
	/* box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2), 0 0px 10px rgba(0, 0, 0, 0.24); */
}

/* Change group animations */
.slide-left {
	animation: slideLeft 0.2s; /* ease-out */
}

.slide-right {
	animation: slideRight 0.2s; /*ease-out*/
}

@keyframes fadeIn05 {
	from {
		opacity: 0;
	}
	to {
		opacity: 0.5;
	}
}

@-webkit-keyframes fadeIn05 {
	from {
		opacity: 0;
	}
	to {
		opacity: 0.5;
	}
}
</style>
