<template>
	<div ref="calendarElement" class="xone-calendar">
		<!-- Week Days -->
		<ol>
			<li
				style="display: flex; justify-content: center"
				:style="{
					backgroundColor: (attributes.cellBorderColor && attributes.cellBorderColor) || 'lightgray',
				}"
				v-for="day in weekDays"
				:key="`calendar-${day}`"
			>
				<!-- Week Day -->
				<p>
					{{ day }}
				</p>
			</li>
		</ol>
		<!-- Days -->
		<ol class="xone-calendar-days">
			<li
				v-for="item in dateItems"
				:key="`calendar-${item.day}-${item.id}`"
				:style="{
					backgroundColor: item.isCurrentMonth
						? item.date.isSameDate(currentDate) && attributes.cellSelectedBgColor
							? attributes.cellSelectedBgColor
							: (attributes.cellBgColor && attributes.cellBgColor) || 'white'
						: (attributes.cellOtherMonthBgColor && attributes.cellOtherMonthBgColor) || 'rgba(0,0,0,.1)',
					border: `1px ${
						item.date.isSameDate(currentDate)
							? (attributes.cellSelectedBorderColor && attributes.cellSelectedBorderColor) || 'lightgray'
							: 'transparent'
					} solid`,
				}"
				@click="onDateSelected(item)"
			>
				<!-- Item Day -->
				<div
					:style="{
						backgroundColor: item.date.isSameDate(new Date()) && (attributes.cellSelectedBgColor || 'lightgray'),
						color: attributes.cellForeColor && attributes.cellForeColor,
					}"
				>
					<p>{{ item.day }}</p>
				</div>
				<!-- Day events -->
				<div class="xone-calendar-events">
					<div v-for="value in item.values" :key="`calendar-event-${value.index}-${value.i}`" :style="{ backgroundColor: value.color }"></div>
				</div>
			</li>
		</ol>
	</div>
</template>

<script>
import { inject, onMounted, onUnmounted, PropType, ref, Ref } from "vue";

import { PropAttributes } from "../../../composables/XoneAttributesHandler";
import { XoneDataObject } from "../../../composables/appData/core/XoneDataObject";
import { XoneDataCollection } from "../../../composables/appData/core/XoneDataCollection";
import { weekStartLocale, getWeekDays } from "../../../composables/helperFunctions/DateHelper";
import { SwipeHandler } from "../../../composables/SwipeHandler";
import { XoneControl, XoneView } from "../../../composables/XoneViewsHandler";
import { generateUniqueId } from "../../../composables/helperFunctions/StringHelper";
import XmlNode from "../../../composables/appData/Xml/JSONImpl/XmlNode";

export default {
	name: "Map",
	props: {
		/**
		 * xoneDataObject
		 * @type {PropType<XoneDataObject>}
		 * */
		xoneDataObject: { type: Object, required: true },
		/**
		 * attributes
		 * @type { PropType<PropAttributes>}
		 */
		attributes: { type: Object, default: null, required: true },
		controlWidth: { type: Number, default: 0 },
		controlHeight: { type: Number, default: 0 },
	},
	setup(props) {
		/**
		 * date items to show in calendar
		 * @type {Ref<Array<object>>}
		 */
		const dateItems = ref([]);

		/**
		 * calendarElement
		 * @type {Ref<HTMLElement>}
		 */
		const calendarElement = ref();

		/**
		 * Contents
		 * @type {Ref<XoneDataCollection>}
		 */
		const contents = ref();

		/**
		 * xoneView
		 * @type {XoneView}
		 */
		const xoneView = inject("xoneView");

		let contentsItems = [];

		// Control key attributes
		let dateFrom, dateTo, timeFrom, timeTo, colorView;

		// Init calendar
		onMounted(async () => {
			contents.value = await props.xoneDataObject.getContents(props.attributes.contents);
			// Get key attributes

			/**
			 * m_xmlNode
			 * @type {{m_xmlNode:XmlNode}}
			 */
			const { m_xmlNode } = contents.value;

			m_xmlNode.SelectNodes("prop").forEach((/** @type {XmlNode} */ e) => {
				if (e.getAttrValue("colorview") === "true") colorView = e.getAttrValue("name");
				if (e.getAttrValue("datefrom") === "true") dateFrom = e.getAttrValue("name");
				if (e.getAttrValue("dateto") === "true") dateTo = e.getAttrValue("name");
				if (e.getAttrValue("timefrom") === "true") timeFrom = e.getAttrValue("name");
				if (e.getAttrValue("timeto") === "true") timeTo = e.getAttrValue("name");
			});
			// Add control to view
			const xoneControl = new XoneControl(props.attributes.name, calendarElement.value);
			xoneControl.refresh = () => refresh();
			xoneControl.nextMonth = () => changeMonth(1);
			xoneControl.previusMonth = () => changeMonth(-1);
			// xoneView.deleteItem = (...Args) => console.log("deleteItem ", Args);
			xoneView.addControl(xoneControl);

			refresh(true);

			await contents.value.executeCollAction("ondateselected", null, currentDate.value).catch(console.error);
		});

		// Close calendar
		onUnmounted(() => {
			if (contents.value) contents.value.clear();
		});

		/**
		 * Refresh Calendar
		 */
		const refresh = async (isFirstLoad = false) => {
			const moveTo = contents.value.getVariables("moveto");
			if (moveTo) {
				contents.value.setVariables("moveto", null);
				if (moveTo === "next") {
					await changeMonth(1);
				} else if (moveTo === "prev") {
					await changeMonth(-1);
				}
			} else drawMonth();
			loadDataAsync(isFirstLoad);
		};

		// Change to Next / Previus Month
		const changeMonth = async (value) => {
			try {
				if (value > 0) currentDate.value = currentDate.value.nextMonth();
				else currentDate.value = currentDate.value.previusMonth();

				drawMonth();

				calendarElement.value.animate(
					[
						{ opacity: 0, transform: `translateX(${value > 0 ? 100 : -100}px)` },
						{ opacity: 1, transform: "translateX(0)" },
					],
					{ duration: 100 }
				);
				await new Promise((resolve) => setTimeout(() => resolve(), 100));
				// executenode ondateselected
				await contents.value.executeCollAction("ondateselected", null, currentDate.value);
				// executenode onpageselected
				await contents.value.executeCollAction("onpageselected", null, currentDate.value);

				loadDataAsync();
			} catch (ex) {
				console.error(ex);
			}
		};

		//
		// Change current month by swipe
		const swipeHandler = new SwipeHandler(changeMonth);
		onMounted(() => {
			calendarElement.value.setAttribute("swipeable", false);
			swipeHandler.init(calendarElement.value);
		});

		onUnmounted(() => swipeHandler.clear());

		let currentDate = ref(new Date());

		/**
		 * Draw selected month
		 */
		const drawMonth = () => {
			// Reset items
			dateItems.value = [];

			// Selected month days count
			const monthDays = currentDate.value.monthDays();

			//
			// Add days from previus month

			// First day of the month
			let firstWeekDayOfMonth = currentDate.value.firstWeekDayOfMonth();

			// Week start day
			let weekStart = weekStartLocale();

			if (weekStart === 1 && firstWeekDayOfMonth === 0) firstWeekDayOfMonth = 7;

			if (firstWeekDayOfMonth > weekStart) {
				// Previus  month
				const previusMonthDate = currentDate.value.previusMonth();

				// Fill previus month days
				let previusMonthDay = previusMonthDate.monthDays() - firstWeekDayOfMonth + weekStart + 1;
				for (let i = firstWeekDayOfMonth; i > weekStart; i--) {
					const date = new Date(previusMonthDate.getFullYear(), previusMonthDate.getMonth(), previusMonthDay);
					// Push item>
					dateItems.value.push({
						id: generateUniqueId(),
						day: previusMonthDay,
						date,
						isCurrentMonth: false,
						values: [],
					});
					previusMonthDay++;
				}
			}

			// Add  month days
			for (let i = 1; i <= monthDays; i++) {
				const date = new Date(currentDate.value.getFullYear(), currentDate.value.getMonth(), i);
				// Push item>
				dateItems.value.push({
					id: generateUniqueId(),
					day: i,
					date,
					isCurrentMonth: true,
					values: [],
				});
			}

			//
			// Add days from next month

			// Week end day
			const weekEnd = weekStart === 0 ? 6 : 7;

			// Last day of the month
			let lastWeekDayOfMonth = currentDate.value.lastWeekDayOfMonth();
			if (weekEnd === 7 && lastWeekDayOfMonth === 0) lastWeekDayOfMonth = 7;
			let nextMonthDay = 1;

			// Next  month
			const nextMonthDate = currentDate.value.nextMonth();

			for (let i = lastWeekDayOfMonth; i < weekEnd; i++) {
				const date = new Date(nextMonthDate.getFullYear(), nextMonthDate.getMonth(), nextMonthDay);
				dateItems.value.push({
					id: generateUniqueId(),
					day: nextMonthDay,
					date,
					isCurrentMonth: false,
					values: [],
				});
				nextMonthDay++;
			}

			// executenode oncelldraw
			dateItems.value.forEach((e) => contents.value.executeCollAction("oncelldraw", null, e.date).catch(console.error));
		};

		let currentContentsFilter = "";
		/**
		 * Load contents data
		 */
		const loadDataAsync = (isFirstLoad = false) => {
			if (isFirstLoad && contents.value.length !== 0) return;
			// Add filter from current month
			contents.value.setFilter(contents.value.getFilter()?.replace(currentContentsFilter, ""));
			currentContentsFilter = `${dateFrom}>='${dateItems.value[0].date}' AND ${dateTo}<='${dateItems.value[dateItems.value.length - 1].date}'`;
			contents.value.setFilter(currentContentsFilter);
			// Load data
			contents.value.loadAll(false).then(() => drawMarkers());
		};

		/**
		 * Draw calendar markers
		 */
		const drawMarkers = async () => {
			contentsItems.value = [];
			// Clear dateItems values
			Object.values(dateItems.value).forEach((value) => (value.values = []));
			for (let i = 0; i < contents.value.length; i++) {
				const contentsItem = await contents.value.get(i);
				contentsItems.value.push({
					index: i,
					obj: contentsItem,
				});

				/**
				 * contentsDate
				 * @type {Date}
				 */
				const contentsDate = contentsItem[dateFrom];
				const color = contentsItem[colorView];

				if (!contentsDate || !color) continue;

				// Fill dateItems values
				Object.values(dateItems.value).forEach((value) => {
					if (value.date.isSameDate(contentsDate)) {
						value.values.push({ id: i, itemIndex: value.index, color });
					}
				});
			}
		};

		const onDateSelected = async (item) => {
			try {
				let currentMonth = currentDate.value.getMonth();
				currentDate.value = item.date;

				if (currentDate.value.getMonth() !== currentMonth) {
					// drawMonth
					drawMonth();
					calendarElement.value.animate(
						[
							{ opacity: 0, transform: `translateX(${currentDate.value.getMonth() > currentMonth ? 100 : -100}px)` },
							{ opacity: 1, transform: "translateX(0)" },
						],
						{ duration: 100 }
					);
					await new Promise((resolve) => setTimeout(() => resolve(), 100));
					// executenode ondateselected
					await contents.value.executeCollAction("ondateselected", null, item.date);
					// executenode onpageselected
					await contents.value.executeCollAction("onpageselected", null, item.date);
					loadDataAsync();
				}
				// executenode ondateselected
				else await contents.value.executeCollAction("ondateselected", null, item.date);
			} catch (ex) {
				console.log(ex);
			}
		};

		return {
			calendarElement,
			contentsItems,
			dateItems,
			currentDate,
			onDateSelected,
			weekDays: getWeekDays(props.attributes.weekdaysLongname),
		};
	},
};
</script>

<style scoped>
.xone-calendar {
	display: flex;
	flex-direction: column;
	position: relative;
	top: 0;
	left: 0;
	background-color: white;
	overflow: hidden;
	width: var(--contents-width);
	height: var(--contents-height);
	max-height: var(--contents-max-height);
}

ol {
	list-style: none;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
}

.xone-calendar-days {
	grid-auto-rows: 1fr;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border: 1px lightgray solid;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	grid-gap: 1px;
	background-color: lightgray;
}

.xone-calendar-header {
	background-color: white;
}

.xone-calendar-days li:hover {
	outline: 1px #ececec solid;
	outline-offset: -1px;
}

li {
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	border: 1px transparent solid;
	background-color: white;
	cursor: pointer;
}

li > div:first-child {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 1px;
	right: 1px;
	width: 2ch;
	height: 2ch;
	font-size: 0.6rem;
	padding: 3px;
	border-radius: 90px;
	color: black;
	cursor: pointer;
	z-index: 1;
}

li > p {
	font-size: 0.75rem;
	font-weight: lighter;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
}

.xone-calendar-events {
	padding: 0 5% 0;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	height: 100%;
	width: 90%;
	background-color: transparent;
}

.xone-calendar-events > div {
	margin: auto 1px 10% 1px;
	flex-grow: 1;
	height: 15%;
	max-height: 15px;
	border-radius: 2px;
	/* animation: fadeIn 0.3s; */
}
</style>
