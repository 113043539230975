import { createRouter, createWebHashHistory } from "vue-router"; //createWebHistory//createWebHashHistory

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
  },
  // {
  //   path: "/entrypoint/:id?",
  //   name: "EntryPoint",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Home.vue"),
  // },
  {
    path: "/obj",
    name: "Coll",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
  },
  {
    path: "/login/:id?",
    name: "Login",
    component: () => import(/* webpackChunkName: "coll" */ "../views/Home.vue"),
  },
  {
    path: "/logon/",
    name: "Logon",
    component: () =>
      import(/* webpackChunkName: "coll" */ "../views/Login.vue"),
  },

  // {
  //   path: "/login",
  //   name: "Login",
  //   component: () =>
  //     import(/* webpackChunkName: "coll" */ "../views/Login.vue"),
  // },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
