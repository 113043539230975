import { computed, nextTick, ref, Ref, ComputedRef } from "vue";
import { XoneDataCollection } from "./appData/core/XoneDataCollection";
import xoneUI from "./XoneUI";

/**
 * @typedef {Object} RowInfo
 * @property {number} id
 * @property {string} recordId
 * @property {function(any): void} setRecordId
 * @property {boolean} visible
 * @property {string} height
 * @property {string} width
 * @property {booolean} isExpanded
 * @property {booolean} isLoaded
 * @property {booolean} isFirstLoad
 * @property {function(): void} refresh
 * @property {function(): void} setIsLoaded
 * @property {function(function): void} setRefresh
 */

/**
 * ContentsLoaderHandler
 */
export class ContentsLoaderHelper {
	/**
	 * Contents items info (index, size, visibility)...
	 * @type {Ref<RowInfo[]>}
	 */
	contentsRowsInfo = ref([]);

	/**
	 * @type {HTMLElement}
	 */
	element;

	/**
	 * @type {XoneDataCollection}
	 */
	contents;

	/**
	 * @type {number}
	 */
	rowsPerPage = 20;

	/**
	 * @type {number}
	 */
	rowsPerPageByObjectId = 5;

	/**
	 * @type {number}
	 */
	rowsPerLoad = 50;

	/**
	 * @type {boolean}
	 */
	isCheckingRowsVisibility;

	/**
	 * @type {ref<boolean>}
	 */
	isLoadingRowsInfo = ref(true);

	/**
	 * Bloquea la carga de nuevos rows, se utiliza para poder ocultar el loading sin que eso conlleve agregar más rows innecesarios al contents si entra de nuevo automáticamente por un evento de scroll
	 * @type {boolean}
	 */
	lockLoadRowsInfo = false;

	/**
	 * @type {boolean}
	 */
	isInGroup;

	/**
	 * @type {Ref<number>}
	 */
	rowsLength = ref(0);

	/**
	 * @type {Map<string,HTMLElement>}
	 */
	mapDivElements = new Map();

	/**
	 * @type {string}
	 */
	elementName;

	/**
	 * @type {boolean}
	 */
	allRecordsLoaded = false;

	/**
	 * loadByObjectId, si el contents carga el mismo nº de registros que se piden, es porque es un content "pesado" en datos, cargaremos dinamicamente de row en row para mejorar la navegacion (fluid-load)
	 * @type {boolean}
	 */
	loadByObjectId;

	/**
	 * @type {string}
	 */
	breadcrumbId;

	/**
	 * @type {ref<boolean>}
	 */
	isLoading = ref(true);

	/**
	 * Constructor
	 * @param {string} elementName
	 * @param {string} breadcrumbId
	 */
	constructor(elementName, breadcrumbId) {
		this.elementName = elementName;
		this.breadcrumbId = breadcrumbId;
	}

	/**
	 * bindOnScroll
	 * @param {HTMLElement} element
	 */
	bindOnScrollEvent(element) {
		this.element = element;
		element.addEventListener("scroll", this.onScroll.bind(this));
	}

	/**
	 * clear
	 */
	clear() {
		this.reset();
		this.element.removeEventListener("scroll", this.onScroll);
	}

	/**
	 * getContentsRowsInfo
	 * @returns {Ref<Array<RowInfo>>}
	 */
	getContentsRowsInfo() {
		return this.contentsRowsInfo;
	}

	/**
	 * getIsLoading
	 * @return {ComputedRef<boolean>} value
	 */
	getIsLoading() {
		// return computed(() => this.isLoading.value); // || this.isLoadingRowsInfo.value);
		return computed(() => this.isLoading.value || this.isLoadingRowsInfo.value);
	}

	/**
	 * getrowsLength
	 * @returns {Ref<number>} value
	 */
	getRowsLength() {
		return this.rowsLength.value;
	}

	/**
	 * setPaginationSize
	 * @param {number|undefined} value
	 */
	setPaginationSize(value) {
		if (!value) return;
		this.rowsPerPage = value;
		this.rowsPerPageByObjectId = value;
	}

	/**
	 * reset
	 */
	async reset() {
		this.lockLoadRowsInfo = false;
		this.mapDivElements.clear(); // Clear divs cache
		this.contentsRowsInfo.value = []; // Clear rows info
		this.rowsLength.value = 0; // Init rowsLenght
		this.allRecordsLoaded = false; // Init allRecordsLoaded
		// this.isLoadingRowsInfo.value = false; // Init IsLoadingRowsInfo
		this.isCheckingRowsVisibility = false; // Init isCHeckingRowVisibility
		await nextTick();
	}

	/**
	 * On contents scroll
	 * @param {*} e
	 */
	onScroll() {
		if (this.isCheckingRowsVisibility || this.isLoadingRowsInfo.value || this.isLoading.value) {
			if (this.timeoutOnScroll) clearTimeout(this.timeoutOnScroll);
			return (this.timeoutOnScroll = setTimeout(() => this.onScroll())), 25;
		}
		// Check elements visibility in scroll
		this.checkScrollElementsVisibility();
		// Check if we have to load more child components
		this.checkLoadRowsInfo();
	}

	/**
	 * checkScrollElementsVisibility
	 * @param {*} e
	 */
	checkScrollElementsVisibility() {
		try {
			this.isCheckingRowsVisibility = true;
			if (this.scrollElementsVisibilityTimeout) clearTimeout(this.scrollElementsVisibilityTimeout);
			this.scrollElementsVisibilityTimeout = setTimeout(() => {
				this.contentsRowsInfo.value.forEach((/** @type {RowInfo} */ element) => {
					const { scrollTop, clientHeight } = this.element;
					// Get Elements from cache
					let divElement = this.mapDivElements.get(element.id);
					// Cache elements
					if (!divElement) {
						divElement = document.getElementById(`${this.elementName.replace("@", "")}${element.id}${this.breadcrumbId}`);
						if (!divElement) return;
						this.mapDivElements.set(element.id, divElement);
					}
					if (
						// Hide elements above scroll
						divElement.offsetTop + divElement.clientHeight + window.outerHeight < scrollTop ||
						// Hide elements below scroll
						(divElement.offsetTop + divElement.clientHeight > scrollTop + clientHeight + window.outerHeight &&
							divElement.offsetTop > scrollTop + clientHeight)
					) {
						// set height
						if (element.height === "auto") element.height = divElement.clientHeight ? divElement.clientHeight + "px" : "auto";
						// set width
						if (element.width === "auto") element.width = divElement.clientWidth ? divElement.clientWidth + "px" : "auto";
						// set visibility
						element.visible = false;
					} else if (
						// Show elements on scroll
						!element.visible
					) {
						element.height = "auto";
						element.width = "auto";
						element.visible = true;
					}
					this.isCheckingRowsVisibility = false;
				});
			}, 100);
		} catch {
			this.isCheckingRowsVisibility = false;
		}
	}

	checkLoadRowsInfo() {
		/** @type {HTMLDivElement} */
		const element = this.element;

		if (
			(this.allRecordsLoaded && this.contentsRowsInfo.value.length >= this.rowsLength.value) ||
			element.scrollHeight - element.clientHeight - window.outerHeight / 2 >= element.scrollTop
		)
			return;

		this.loadRowsInfo(null, element);
	}

	/**
	 * loadRows
	 * @param {XoneDataCollection} [contents]
	 * @param {import("./XoneAttributesHandler").PropAttributes} [attributes]
	 * @param {boolean} [isFirstLoad]
	 */
	async loadRows(contents = null, attributes = null, isFirstLoad = false) {
		try {
			if (contents) {
				await this.reset();
				this.contents = contents;
			}
			this.isLoading.value = true;
			this.isLoadingRowsInfo.value = true;

			/**
			 * coleccion special
			 * @type {boolean}
			 */
			const isSpecial = this.contents.m_xmlNode.getAttrValue("special") === "true";

			/**
			 * carga fluida, carga los registros de 1 en 1 para no bloquear el UI
			 * @type {boolean}
			 */
			const isFluidLoad = this.contents.m_xmlNode.getAttrValue("fluid-load") === "true" || attributes?.fluidLoad;

			if (isFirstLoad && this.contents.length !== 0) {
				// Se supone que ya se ha cargado en un before-edit y aquí no tenemos que hacer nada?
			} else if (!isSpecial) {
				if (this.contents.isLock()) await this.contents.loadAll(false);
				else
					await this.contents.loadAll(false, {
						start: this.getRowsLength(),
						length: this.rowsPerLoad,
					});
			} else if (this.getRowsLength() === this.contents.length) this.allRecordsLoaded = true;

			// Marcamos si se han cargado todos los datos
			if (this.contents.length === 0 || this.contents.isLock() || this.contents.length !== this.rowsPerLoad) this.allRecordsLoaded = true;
			this.rowsLength.value += this.contents.length;

			// Si tenemos un contents con muchos datos o queremos carga fluida, usaremos el ID del objeto para la carga
			if (contents && !isSpecial) this.loadByObjectId = this.rowsPerLoad === this.contents.length || isFluidLoad;
		} catch (ex) {
			xoneUI.showSnackbar({ color: "#F44336", textColor: "white", text: ex });
			this.allRecordsLoaded = true;
			console.error(ex);
		} finally {
			this.isLoading.value = false;
		}
	}

	/**
	 * loadRowsInfo
	 */
	async loadRowsInfo() {
		if (this.lockLoadRowsInfo) return;
		this.lockLoadRowsInfo = true;
		this.isLoadingRowsInfo.value = true;

		// Si tenemo scrollbar vamos a hacer un pequeño delay para no bloquear el  UI distribuyendo el proceso
		if (this.contentsRowsInfo.value.length === 0 || this.element.scrollHeight > this.element.clientHeight)
			await new Promise((resolve) => setTimeout(() => nextTick(() => resolve()), 1));

		const newRowsNumber = this.loadByObjectId ? this.rowsPerPageByObjectId : this.rowsPerPage;

		const init = this.contentsRowsInfo.value.length === 0 ? 0 : this.contentsRowsInfo.value[this.contentsRowsInfo.value.length - 1].id + 1;

		const end = init + newRowsNumber;

		for (let i = init; i < end; i++) {
			// Si no estamos en el grupo del contents esperamos
			while (!this.isInGroup) await new Promise((resolve) => setTimeout(() => resolve(), 250));

			if (i >= this.getRowsLength()) {
				// Todos los datos cargados
				if (this.allRecordsLoaded) {
					return this.resetLoadRows();
				}
				// Cargamos más datos
				await this.loadRows();
				if (this.allRecordsLoaded) {
					return this.resetLoadRows();
				}
			}
			/** @type {RowInfo} */
			const rowInfo = {
				id: i,
				recordId: !this.loadByObjectId
					? null
					: await (async () => {
							let iData = i;
							while (iData >= this.rowsPerLoad) iData -= this.rowsPerLoad;
							try {
								const obj = await this.contents.get(iData);
								return obj.ID.toString();
							} catch (ex) {
								console.error("Error getting XoneDataObject ID", ex);
								return null;
							}
					  })(),
				setRecordId: (value) => (rowInfo.recordId = value?.toString()),
				visible: true,
				height: "auto",
				width: "auto",
				isExpanded: false,
				isFirstLoad: init === 0,
				refresh: () => {},
				setRefresh: (callbackFunction) => (rowInfo.refresh = callbackFunction),
				setIsLoaded: (value) => (rowInfo.isLoaded = value),
			};
			this.contentsRowsInfo.value.push(rowInfo);
		}

		this.resetLoadRows();
	}

	async resetLoadRows() {
		// delete window.isLoadingContents;
		this.isLoadingRowsInfo.value = false;

		/** @type {HTMLDivElement} */
		const element = this.element;

		let hasScroll = false;

		// Vamos a meter un delay para que rendericen los rows y calculemos si necesitamos cargar más filas o no...
		for (let i = 0; i < 100; i++) {
			if (this.element.scrollHeight > element.clientHeight + 200) {
				hasScroll = true;
				this.checkScrollElementsVisibility();
				break;
			}
			if (!this.contentsRowsInfo.value.some((e) => !e.isLoaded)) break;
			await new Promise((resolve) => setTimeout(() => resolve(), 5));
		}

		const newRowsNumber = this.loadByObjectId ? this.rowsPerPageByObjectId : this.rowsPerPage;

		this.lockLoadRowsInfo = false;

		if (hasScroll || this.contentsRowsInfo.value.length < newRowsNumber) return;

		this.loadRowsInfo();
	}
}
