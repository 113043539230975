import { createApp, createSSRApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Container from "./components/Container.vue";
import "./composables/helperFunctions/DateHelper";
import "./composables/helperFunctions/DeviceHelper";
import "./composables/ScriptGlobalObjects";
import dragAndDrop from "./composables/DragAndDrop";

// Get App Configuration File and Script_Wrapper
const getConfig = Function(
  "cbF",
  "(async()=>{try{const c=await import('../configuration.js');cbF(c.default);}catch(ex){console.error('Error loading configuration.js',ex);}})()"
);

getConfig((config) => {
  const mode = config.mode || "SPA";

  if (mode === "SSR")
    // SSR
    // TODO: crear  el servidor de express y agregar el renderToString para que soporte renderizado a nivel de servidor, va a llegar antes o despues todo esto...
    createSSRApp(App)
      .use(router)
      .component("Container", Container)
      .mount("#app");
  // else if (mode==="ELECTRON") TODO: agregar una capa de electron para que compile como app de escritorio
  // SPA / PWA
  else {
    if (mode === "DESIGNER") dragAndDrop.setIsDesigner(true);
    createApp(App)
      .use(router)
      .component("Container", Container)
      .mount("#app");
  }

  // PWA
  if (mode === "PWA")
    if (navigator.serviceWorker)
      // SW
      navigator.serviceWorker.register("./sw.js");

  // Disable navigator forward button
  window.addEventListener(
    "popstate",
    (event) => {
      if (typeof event.state == "object" && event.state.obsolete !== true) {
        history.replaceState({ obsolete: true }, "");
        history.pushState(event.state, "");
      }

      if (typeof event.state == "object" && event.state.obsolete === true) {
        history.back();
      }
    },
    false
  );

  // Lock scale
  const viewportmeta = document.querySelector('meta[name="viewport"]');
  if (viewportmeta) {
    viewportmeta.content =
      "width=device-width, minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0";
    document.body.addEventListener(
      "gesturestart",
      function() {
        viewportmeta.content =
          "width=device-width, minimum-scale=0.25, maximum-scale=1.6";
      },
      false
    );
  }
});
