<template>
	<div
		v-if="containerWidth && containerHeight"
		class="-"
		:style="{
			'--app-width': `${containerWidth}px`,
			'--app-height': `${containerHeight}px`,
		}"
	>
		<!-- Layout breadcrumb -->
		<div class="xone-layout" v-if="webLayoutBreadcrumb">
			<Coll :breadcrumb="webLayoutBreadcrumb" :isLayout="true"></Coll>
		</div>

		<!-- Router -->
		<router-view v-else />

		<!-- UI Components -->
		<SnackBar />
		<Toast />
		<Loader />
		<MsgBox />
		<Camera />
		<Url />
		<Offline />

		<!-- Drag & Drop -->
		<SelectedControl />
		<OverControl />
		<DragControl />
	</div>
</template>

<script>
// Vue
import { provide, ref, Ref, ComputedRef, onMounted, onUnmounted, computed, watch } from "vue";
// Components
import Coll from "@/components/Coll";
import Toast from "./components/uiComponents/Toast.vue";
import SnackBar from "./components/uiComponents/SnackBar.vue";
import Loader from "./components/uiComponents/Loader.vue";
import MsgBox from "./components/uiComponents/MsgBox.vue";
import Camera from "./components/uiComponents/Camera.vue";
import Url from "./components/uiComponents/Url.vue";
import Offline from "./components/uiComponents/Offline.vue";
import SelectedControl from "./components/dragAndDrop/SelectedControl";
import OverControl from "./components/dragAndDrop/OverControl";
import DragControl from "./components/dragAndDrop/DragControl";
// Composables
import { XoneApplication } from "./composables/appData/core/XoneApplication";
import { FileManagerOnline } from "./composables/appData/Manager/FileManager";
import AppDataHandler, { Breadcrumb } from "./composables/AppDataHandler";
import xoneAppHandler from "./composables/XoneAppHandler";
import { useRoute, useRouter } from "vue-router";
import xoneUI, { setDoLogin, setEntryPointFake } from "./composables/XoneUI";
import { setWidthFactor, setHeightFactor } from "./composables/XoneAnimation";
import { setAppPath } from "./composables/helperFunctions/ImageHelper";
import { setIsScaleFontsize, setWebFontFactor } from "./composables/XoneAttributesHandler";
import { addFontFaces } from "./composables/helperFunctions/FontsHelper";

export default {
	components: {
		Coll,
		Toast,
		SnackBar,
		Loader,
		MsgBox,
		Camera,
		Url,
		Offline,
		SelectedControl,
		OverControl,
		DragControl,
	},
	setup() {
		const version = "";

		console.log(
			`%c b y %c X %c >O n e %c ${version}`,
			"background: whitesmoke; color: #1F3C6E; font-weight: bold; display: block; font-size: 17px;",
			"background: #4CABD5   ; color: white  ; font-weight: bold; display: block; font-size: 17px;",
			"background: #1F3C6E   ; color: white  ; font-weight: bold; display: block; font-size: 17px;",
			"color: #1F3C6E"
		);

		const router = useRouter();
		const route = useRoute();

		/**
		 * XOne Web Layout
		 * @type {ComputedRef<Breadcrumb>}
		 */
		const webLayoutBreadcrumb = computed(() => {
			const breadcrumb = AppDataHandler.getBreadcrumbs().filter((e) => e.isWebLayout);
			return breadcrumb.length !== 0 ? breadcrumb[0] : null;
		});
		/**
		 * routeBreadcrumbs: router object Stack
		 * @type {ComputedRef<Breadcrumb[]>}
		 */
		const routeBreadcrumbs = computed(() => AppDataHandler.getBreadcrumbs().filter((e) => !e.isWebLayout && !e.isMsgbox && !e.isContentsRow));
		provide("routeBreadcrumbs", routeBreadcrumbs);

		/**
		 * lastBreadcrumb
		 * @type {ComputedRef<Breadcrumb>}
		 */
		const lastBreadcrumb = computed(() => routeBreadcrumbs.value.length !== 0 && routeBreadcrumbs.value[routeBreadcrumbs.value.length - 1]);

		// Provide lastBreadcrumb to child components
		provide("lastBreadcrumb", lastBreadcrumb);

		//
		// Manage Window Size / Scale

		/**
		 * appWidth
		 * @type {Ref<number>}
		 */
		let appWidth = ref();

		/**
		 * appHeight
		 * @type {Ref<number>}
		 */
		let appHeight = ref();

		provide("appSize", { appWidth, appHeight });

		/**
		 * containerWidth
		 * @type {Ref<number>}
		 */
		let containerWidth = ref(null);

		/**
		 * containerHeight
		 * @type {Ref<number>}
		 */
		let containerHeight = ref(null);

		// Check if an input is focused in order to handle keyboard in mobile devices
		const isInputFocused = ref(false);
		provide("isInputFocused", isInputFocused);

		watch(
			() => isInputFocused.value,
			(newValue) => {
				if (!newValue) setTimeout(() => onResize(), 250);
			}
		);

		let resizeTimeout;
		let allowZoom = false;
		/**
		 * on window resize
		 */
		const onResize = () => {
			if (resizeTimeout) clearTimeout(resizeTimeout);

			resizeTimeout = setTimeout(() => {
				const zoom = allowZoom ? window.devicePixelRatio : 1;
				if (isInputFocused.value) return;
				containerWidth.value = (appWidth.value && window.innerWidth > appWidth.value ? appWidth.value : window.innerWidth) * zoom - 2;
				containerHeight.value = (appHeight.value && window.innerHeight > appHeight.value ? appHeight.value : window.innerHeight) * zoom - 2;
				// Send resolution macros
				xoneAppHandler && xoneAppHandler.setVisualConditionsMacros(containerWidth.value, containerHeight.value);

				sizeConditions.value = xoneAppHandler.sizeConditions;
				orientation.value = xoneAppHandler.orientation;
			}, 50);
		};

		onMounted(() => window.addEventListener("resize", onResize));
		onUnmounted(() => window.removeEventListener("resize", onResize));

		provide("containerSize", { containerWidth, containerHeight });

		/**
		 * Size Conditions
		 * @type {Ref<string>}
		 */
		const sizeConditions = ref("");
		provide("sizeConditions", sizeConditions);

		/**
		 * Orientation
		 * @type {Ref<string>}
		 */
		const orientation = ref("");
		provide("orientation", orientation);

		//
		// Scale Factor

		/**
		 * widthFactor
		 * @type {ComputedRef<number>}
		 */
		const widthFactor = computed(() => (appWidth.value ? containerWidth.value / appWidth.value : 1));

		/**
		 * heightFactor
		 * @type {ComputedRef<number>}
		 */
		const heightFactor = computed(() => (appHeight.value ? containerHeight.value / appHeight.value : 1));
		// provide scaleFactor to child components
		provide("scaleFactor", { widthFactor, heightFactor });

		watch(
			() => widthFactor.value,
			(newValue) => setWidthFactor(newValue)
		);
		watch(
			() => heightFactor.value,
			(newValue) => setHeightFactor(newValue)
		);

		// displayScreenThresholds
		const displayScreenThresholds = ref(false);
		provide("displayScreenThresholds", displayScreenThresholds);

		const showBreadcrumbs = ref(false);
		provide("showBreadcrumbs", showBreadcrumbs);

		/**
		 * Init appData
		 */
		onMounted(() => {
			const appData = new XoneApplication();

			// provide appData
			AppDataHandler.setAppData(appData);

			// set UI
			appData.setUserInterface(xoneUI);

			// Get App Configuration File and Script_Wrapper
			const getConfig = Function(
				"cbF",
				"(async()=>{try{const c=await import('../configuration.js');const{__SCRIPT_WRAPPERASYNC}=await import('../source/__SCRIPT_WRAPPER__.js');cbF({...c.default,sWA:__SCRIPT_WRAPPERASYNC});}catch(ex){console.error('Error loading configuration.js and __SCRIPT_WRAPPER__.js files',ex);}})()"
			);

			getConfig(async (config) => {
				// path
				let path = (config.path ?? "").trim();
				if (path === "/") path = "";
				else if (path && !path.startsWith("/")) path = `/${path}`;
				// Set App Path
				setAppPath(path);
				// Add Font Faces
				addFontFaces();
				// title
				if (config.title) document.title = config.title;
				// screen thresholds
				if (config.displayScreenThresholds) displayScreenThresholds.value = true;
				if (config.showBreadcrumbs) showBreadcrumbs.value = config.showBreadcrumbs;

				if (config.debugging) window.debugging = true;
				// allow zoom
				allowZoom = config.allowZoom;
				// sourcePath
				let sourcePath = `${path}/source`;
				while (sourcePath.includes("//")) sourcePath = sourcePath.replace("//", "/");
				//
				// Init App
				await appData.IniciarApp(
					sourcePath,
					config.sWA,
					new FileManagerOnline(),
					`Data Source=${config.apiUrl};xoneuser=${config.clientId};xonepass=${config.clientSecret};ids=${config.idsUrl ||
						"https://ids.xonedev.cloud/connect/token"}`
				);

				xoneAppHandler.setAppData(appData);

				// Set app width / height
				const appSize = xoneAppHandler.getAppSize();
				appWidth.value = appSize?.width <= 0 ? null : appSize?.width;
				appHeight.value = appSize?.height <= 0 ? null : appSize?.height;

				onResize();

				// scaled fontsize
				const isScaleFontsize = xoneAppHandler.isScaleFontsize();
				setIsScaleFontsize(isScaleFontsize);

				// scaled fontsize
				const webFontFactor = xoneAppHandler.getWebFontFactor();
				if (webFontFactor && !isNaN(webFontFactor)) setWebFontFactor(Number(webFontFactor));

				// set macros
				xoneAppHandler.setInmutableMacros(appData);

				autoLogon = xoneAppHandler.getAutoLogon(); // TODO: crear autologon
				doLogin(autoLogon);
			});
		});

		let autoLogon = false;

		const doLogin = async () => {
			const entryPointFake = setEntryPointFake(route.params?.id);
			if (entryPointFake) {
				autoLogon = true;
				displayScreenThresholds.value = true;
				xoneUI.entryPointFake = entryPointFake;
			}
			if (autoLogon) {
				return AppDataHandler.getAppData().login({
					userName: "admin",
					password: "",
				});
				// .catch(console.error("autologon error"));
			}
			// if (!loginColl) // TODO: crear login integrado
			//   return router.push({ name: "Login", params: { id: "integrated" } });

			await AppDataHandler.addNewXoneDataObject(AppDataHandler.getAppData().getLoginCollectionName(), "Login", false, true);

			// Send setDoLogin to XoneUI
			setDoLogin(doLogin);

			const routeTo = { name: "Login" };

			if (route.params?.id) routeTo.params = { id: route.params.id };
			router.push(routeTo);
		};

		provide("loginFunction", doLogin);

		return {
			webLayoutBreadcrumb,
			containerWidth,
			containerHeight,
		};
	},
};
</script>

<style>
body {
	overscroll-behavior-y: contain;
}

#app {
	font-family: "Roboto", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* text-align: center; */
	display: flex;
	justify-content: center;
	overflow: visible;
}

* {
	padding: 0;
	margin: 0;

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
	scrollbar-width: thin;
	scrollbar-color: lightgrey transparent;
}

div {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

.xone-app {
	display: flex;
	flex-direction: column;
	/* overflow: auto; */
	overflow: visible;
	max-height: 100vh;
	max-width: 100vw;
}
.xone-navbar {
	flex-shrink: 1;
}

.xone-layout,
.xone-dataobjects {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: visible;
	/* border: 1px solid rgba(0, 0, 0, 0.12); */
	/* box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14),
    0 1px 10px rgba(0, 0, 0, 0.12); */
	width: var(--app-width);
	height: var(--app-height);
}

@keyframes fadeIn {
	from {
		z-index: 999999;
		opacity: 0;
	}
	to {
		z-index: 999999;
		opacity: 1;
	}
}

@keyframes slideLeft {
	from {
		z-index: 999999;
		transform: translate(75%, 0);
	}
	to {
		z-index: 999999;
		transform: translate(0, 0);
	}
}

@keyframes slideRight {
	from {
		z-index: 999999;
		transform: translate(-75%, 0);
	}
	to {
		z-index: 999999;
		transform: translate(0, 0);
	}
}

@keyframes zoomIn {
	from {
		z-index: 999999;
		transform: scale(0);
	}
	to {
		z-index: 999999;
		transform: scale(1);
	}
}

@keyframes slideDown {
	0% {
		z-index: 999999;
		transform: translateY(-50px);
	}

	100% {
		z-index: 999999;
		transform: translateY(0);
	}
}

@keyframes slideUp {
	0% {
		z-index: 999999;
		transform: translateY(50px);
	}

	100% {
		z-index: 999999;
		transform: translateY(0);
	}
}

@keyframes right_in {
	from {
		z-index: 999999;
		transform: translateX(100px);
	}
	to {
		z-index: 999999;
		transform: translateX(0px);
	}
}

@-webkit-keyframes fadeIn {
	from {
		z-index: 999999;
		opacity: 0;
	}
	to {
		z-index: 999999;
		opacity: 1;
	}
}

@-webkit-keyframes slideLeft {
	from {
		z-index: 999999;
		transform: translate(75%, 0);
	}
	to {
		z-index: 999999;
		transform: translate(0, 0);
	}
}

@-webkit-keyframes slideRight {
	from {
		z-index: 999999;
		transform: translate(-75%, 0);
	}
	to {
		z-index: 999999;
		transform: translate(0, 0);
	}
}

@-webkit-keyframes zoomIn {
	from {
		z-index: 999999;
		transform: scale(0);
	}
	to {
		z-index: 999999;
		transform: scale(1);
	}
}

@-webkit-keyframes slideDown {
	0% {
		z-index: 999999;
		transform: translateY(-50px);
	}

	100% {
		z-index: 999999;
		transform: translateY(0);
	}
}
@-webkit-keyframes slideUp {
	0% {
		z-index: 999999;
		transform: translateY(50px);
	}

	100% {
		z-index: 999999;
		transform: translateY(0);
	}
}

@-webkit-keyframes right_in {
	from {
		z-index: 999999;
		transform: translateX(100px);
	}
	to {
		z-index: 999999;
		transform: translateX(0px);
	}
}

/* width */
::-webkit-scrollbar {
	width: 7px;
	height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: lightgrey;
	border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/* Leaflet map routing */
.leaflet-routing-container {
	display: none;
}

/* Loader */

.xone-loader {
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10px 0;
	padding-bottom: 25px;
	grid-column: 1 / -1;
	height: 100px;
	animation: fadeIn 0.3s;
}

.xone-loader div:first-child {
	border-radius: 50%;
	border: 4px solid lightgray;
	border-left: 4px solid gray;
	width: 3vh;
	height: 3vh;
	-webkit-animation: spin 0.6s linear infinite;
	animation: spin 0.6s linear infinite;
}

.xone-dataobject {
	position: relative;
	flex-grow: 1;
	animation: loadEffect 0.3s;
	display: flex;
	flex-direction: column;
}

@keyframes loadEffect {
	from {
		opacity: 0.1;
	}
	to {
		opacity: 1;
	}
}

@-webkit-keyframes loadEffect {
	from {
		opacity: 0.1;
	}
	to {
		opacity: 1;
	}
}
/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
