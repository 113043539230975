<template>
	<teleport v-if="url" to="body">
		
		<div class="xone-url" @click="url = null">
			<div>
			<button>X</button>
				<iframe :src="url" loading="lazy"></iframe>
			</div>
		</div>
	</teleport>
</template>

<script>
import { ref } from "vue";
import { setOpenUrlCallback } from "../../composables/XoneUI";
import Button from '../propComponents/Button.vue';
export default {
  components: { Button },
	setup() {
		const url = ref();

		setOpenUrlCallback((Url) => (url.value = Url));

		return { url };
	},
};
</script>

<style scoped>
.xone-url {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.3);
	animation: fadeIn 0.3s zoomIn 0.3s;
	z-index: 99;
}

.xone-url > div {
	width: calc(100vw - 100px);
	height: calc(100vh - 100px);
	background-color: white;
	position: relative;
}

iframe {
	width: 100%;
	height: 100%;
	border: none;
}

button{
	width: 30px; 
	height:30px;
	background: white;
	position:absolute;
	right:0;
	top:-31px;
	outline: none;
	border: none;
	border-radius: 2px;
cursor:pointer;
}
</style>
