<template>
	<div
		ref="contentsElement"
		class="xone-contents"
		:class="{ 'xone-gridview': isGridView, 'xone-slideview': isSlideView }"
		:style="{
			// Size
			height: !attributes.height || attributes.height === 'auto' ? 'auto' : (controlHeight && `${controlHeight}px`) || 'auto',
			maxWidth: attributes.viewMode === 'picturemap' && `${controlWidth}px`,
			flexGrow: attributes.width === 'grow' ? 1 : null,
			// Background
			backgroundColor: attributes.bgColor,
			// is GridView? -> define columns
			'grid-template-columns': isGridView && gridTemplateColumns,
			// Animation if is contents 'TreeView'
			animation: isContents && 'slideDown 0.3s',
			// Set variables
			'--contents-width': `${controlWidth}px`,
			'--contents-height': `${controlHeight}px`,
			'--contents-max-height': `${fitHeight ? `calc(${fitHeight}px - var(--margin-bottom))` : null}`,
			// New web-layout / routerview Container Size
			'--app-width': (isRouterView && controlWidth && `${controlWidth - 2}px`) || 'auto',
			'--app-height': (isRouterView && controlHeight && `${controlHeight - 2}px`) || 'auto',
			overflow: isRouterView || isChartView ? 'hidden' : null,
			flexWrap: attributes.wrap ? 'wrap' : null,
			paddingBottom: attributes.viewMode || isLoading || isContents ? null : '100px',
		}"
		@scroll="onScroll"
	>
		<router-view v-if="isRouterView"></router-view>
		<!-- Map View -->
		<Map
			v-if="isMapView"
			:xoneDataObject="xoneDataObject"
			:controlHeight="fitHeight || controlHeight"
			:controlWidth="controlWidth"
			:attributes="attributes"
		></Map>
		<!-- Calendar View -->
		<Calendar
			v-else-if="isCalendarView"
			:xoneDataObject="xoneDataObject"
			:controlHeight="fitHeight || controlHeight"
			:controlWidth="controlWidth"
			:attributes="attributes"
		></Calendar>
		<!-- Calendar View -->
		<PictureMap
			:style="{
				'--contents-height': `${fitHeight || controlHeight}px`,
			}"
			v-else-if="isPictureMapView"
			:xoneDataObject="xoneDataObject"
			:controlHeight="fitHeight || controlHeight"
			:controlWidth="controlWidth"
			:attributes="attributes"
		></PictureMap>
		<!-- Chart View -->
		<template v-else-if="isChartView">
			<ChartBar
				v-if="isChartBar"
				:xoneDataObject="xoneDataObject"
				:controlHeight="fitHeight || controlHeight"
				:controlWidth="controlWidth"
				:attributes="attributes"
			>
			</ChartBar>
			<ChartPie
				v-else-if="isChartPie"
				:xoneDataObject="xoneDataObject"
				:controlHeight="fitHeight || controlHeight"
				:controlWidth="controlWidth"
				:attributes="attributes"
			>
			</ChartPie>
			<div v-else style="color: #1f3c6e; background: #4cabd5; padding: 10px">
				XOne -> Not implemented chart type, please contact desarrollador@xone.es
			</div>
		</template>
		<!-- Other Contents -->
		<template v-else v-for="rowInfo in contentsRowsInfo" :key="`${breadcrumbId}-${attributes.name}-${rowInfo.id}`">
			<ContentsRow
				:rowInfo="rowInfo"
				:controlWidth="isGridView ? controlWidth / attributes.galleryColumns : controlWidth"
				:controlHeight="fitHeight || controlHeight"
				:attributes="attributes"
				:isSlideView="isSlideView"
				:isDisableEdit="isDisableEdit"
				:isExpanView="isExpanView"
				:rowsLength="rowsLength"
			></ContentsRow>
		</template>
		<!-- Loader -->
		<div v-if="isLoading && !isMapView && !isCalendarView && !isChartView && !isPictureMapView" class="xone-loader">
			<div></div>
		</div>
	</div>
</template>

<script>
import { computed, inject, onMounted, provide, ref, Ref, ComputedRef, watchEffect, PropType, onUnmounted, watch, nextTick } from "vue";

import ContentsRow from "@/components/propComponents/contentsComponents/ContentsRow";
import Map from "@/components/propComponents/contentsComponents/Map";
import Calendar from "@/components/propComponents/contentsComponents/Calendar";
import ChartBar from "@/components/propComponents/contentsComponents/ChartBar";
import ChartPie from "@/components/propComponents/contentsComponents/ChartPie";
import PictureMap from "@/components/propComponents/contentsComponents/PictureMap";
import { XoneDataObject } from "../../composables/appData/core/XoneDataObject";
import { XoneDataCollection } from "../../composables/appData/core/XoneDataCollection";
import { PropAttributes, xoneAttributesHandler } from "../../composables/XoneAttributesHandler";
import { XoneControl, XoneView } from "../../composables/XoneViewsHandler";
import { ContentsLoaderHelper } from "../../composables/ContentsLoaderHandler";
import { generateUniqueId } from "../../composables/helperFunctions/StringHelper";

export default {
	name: "Contents",
	props: {
		/**
		 * xoneDataObject
		 * @type {PropType<XoneDataObject>}
		 * */
		xoneDataObject: { type: Object, required: true },
		/**
		 * attributes
		 * @type { PropType<PropAttributes>}
		 */
		attributes: { type: Object, default: null, required: true },
		isDisableEdit: { type: Boolean, required: true },
		controlHeight: { type: Number, default: 0 },
		controlWidth: { type: Number, default: 0 },
	},
	components: {
		ContentsRow,
		Map,
		ChartBar,
		Calendar,
		ChartPie,
		PictureMap,
	},
	setup(props) {
		/** @type {import('../../composables/AppDataHandler').Objectinfo} */
		const { isContents } = inject("objectInfo");

		/**
		 * Contents
		 * @type {Ref<XoneDataCollection>}
		 */
		const contents = ref();
		// provide contents to child components
		provide("contents", contents);

		/**
		 * xoneView
		 * @type {XoneView}
		 */
		const xoneView = inject("xoneView");

		// provide item to load to child components
		const loadedRowsLength = ref(0);
		provide("loadedRowsLength", loadedRowsLength);

		/**
		 * breadcrumbId
		 * @type {string}
		 */
		const breadcrumbId = inject("breadcrumbId");

		/**
		 * contentsElement
		 * @type {Ref<HTMLElement>}
		 */
		const contentsElement = ref();

		/**
		 * Window Size
		 * @type {{containerWidth: Ref<number>|ComputedRef<number>, containerHeight: Ref<number>|ComputedRef<number>}}
		 */
		const { containerWidth, containerHeight } = inject("containerSize");

		/**
		 * fit height
		 * @type {Ref<number>}
		 */
		const fitHeight = ref();

		/**
		 * Calculate and adjust height to parent container when height attribute is null or auto
		 */
		const fitHeightToContainer = async () => {
			if (props.attributes.viewMode === "picturemap") return;
			const isSpecial = isMapView.value || isCalendarView.value || isChartView.value || isPictureMapView.value;
			try {
				if (!contentsElement.value) return;

				if (isSpecial && (props.attributes.height ?? "auto") !== "auto" && (props.attributes.height ?? "auto") !== "grow")
					return (fitHeight.value = null);

				if (!isSpecial && (props.attributes.height ?? "auto") !== "grow") return (fitHeight.value = null);

				let top = contentsElement.value.parentElement.offsetTop;

				if (fitHeight.value !== props.controlHeight - top) {
					fitHeight.value = props.controlHeight - top;
					return true;
				}
				return false;
			} catch {}
		};

		onMounted(() => {
			fitHeightToContainer();
			const observerHeight = new ResizeObserver(() => fitHeightToContainer());
			observerHeight.observe(contentsElement.value.parentNode);
			onUnmounted(() => observerHeight?.disconnect());
		});

		/**
		 * selectedItem
		 * @type {Ref<number>}
		 */
		const selectedItem = ref(null);
		provide("selectedItem", selectedItem);

		//
		// Data

		const contentsLoaderHelper = new ContentsLoaderHelper(props.attributes.name, breadcrumbId);
		contentsLoaderHelper.setPaginationSize(props.attributes.paginationSize);

		const isLoading = contentsLoaderHelper.getIsLoading();

		onMounted(() => contentsLoaderHelper.bindOnScrollEvent(contentsElement.value));
		onUnmounted(() => contentsLoaderHelper?.clear());

		let lastRefreshActionId = null;

		/**
		 * LoadAll contents async
		 */
		const refresh = async (isFirstLoad = false) => {
			// Refresh only last refresh action
			const refreshActionId = generateUniqueId();
			lastRefreshActionId = refreshActionId;

			// Wait last refresh ends
			while (contents.value && isLoading.value) await new Promise((resolve) => setTimeout(() => resolve(), 100));

			// Check if refresh action is the last one called
			if (lastRefreshActionId !== refreshActionId) return;

			try {
				// load contents
				if (!contents.value) contents.value = await props.xoneDataObject.getContents(props.attributes.contents);

				// dataCollectionLayout.value = contents.value.getLayout(4);

				// LoadAll
				await contentsLoaderHelper.loadRows(contents.value, props.attributes, isFirstLoad);

				loadedRowsLength.value = 0;
				// Load Rows Info
				await contentsLoaderHelper.loadRowsInfo();
			} catch (ex) {
				console.error(ex);
			}
		};

		/** @type {string} */
		const groupId = inject("groupId");

		/** @type {Ref<string>} */
		const { activeGroup } = inject("groupHandler");

		let firstGroup;

		/** @type {Ref<boolean>} */
		const isBeforeEditExecuted = inject("isBeforeEditExecuted");

		onMounted(async () => {
			// Vamos a cargar el contents cuando su grupo sea el activo
			if (isMapView.value || isChartView.value || isCalendarView.value || isPictureMapView.value || isRouterView.value) return;
			// Create XoneControl
			const xoneControl = new XoneControl(props.attributes.name, contentsElement.value);
			// refresh method
			xoneControl.refresh = refresh;
			// refreshRow method
			xoneControl.refreshRow = (index) => {
				try {
					contentsLoaderHelper.getContentsRowsInfo().value[index].refresh();
				} catch {}
			};
			// refreshSelectedRow method
			xoneControl.refreshSelectedRow = () => {
				try {
					contentsLoaderHelper.getContentsRowsInfo().value[selectedItem.value].refresh();
				} catch {}
			};
			// scrollBy method
			xoneControl.scrollBy = (value) => {
				if (!contentsElement.value) return;
				value = value.toString();
				const { scrollHeight } = contentsElement.value;
				if (value.includes("%"))
					return contentsElement.value.scrollTo({
						top: Number((value.replace("%", "") * scrollHeight) / 100),
						behavior: "smooth",
					});
				contentsElement.value.scrollTo({
					top: Number(value.replace("p", "")),
					behavior: "smooth",
				});
			};
			// scrollTo method
			xoneControl.scrollTo = (index) => {
				const element = document.getElementById(`${props.attributes.name.replace("@", "")}${index}${breadcrumbId}`);
				if (element) xoneControl.scrollBy(element.offsetTop);
			};
			// ScrollToTop method
			xoneControl.scrollToTop = () => xoneControl.scrollBy(0);
			// ScrollToBottom method
			xoneControl.scrollToBottom = () => xoneControl.scrollBy(contentsElement.value.scrollHeight);
			// Add control to view
			xoneView.addControl(xoneControl);

			while (!isBeforeEditExecuted.value) await new Promise((resolve) => setTimeout(() => resolve(), 5));

			watchEffect(async () => {
				// Asignamos si el contents se encuentra en el grupo activo
				contentsLoaderHelper.isInGroup = groupId === activeGroup.value;

				if (!firstGroup) firstGroup = activeGroup.value;

				if (contents.value || groupId !== activeGroup.value) return;

				if (groupId !== firstGroup) await new Promise((resolve) => setTimeout(() => resolve(), 350));

				refresh(true);
			});
		});

		// Clear Contents
		onUnmounted(() => {
			if (contents.value) contents.value.clear();
			contentsLoaderHelper?.clear();
		});

		//
		//
		// Viewmodes

		//
		// viewmode gridview
		const isGridView = computed(() => props.attributes.viewMode === "gridview" && !isNaN(props.attributes.galleryColumns));

		const gridTemplateColumns = computed(() => {
			if (!isGridView.value) return;

			return `repeat(${props.attributes.galleryColumns}, ${props.controlWidth / props.attributes.galleryColumns}px)`;
		});

		//
		// viewmode mapview

		const isMapView = computed(() => props.attributes.viewMode === "mapview" || props.attributes.viewMode === "openstreetmap");

		//
		// viewmode slideview

		const isSlideView = computed(() => props.attributes.viewMode === "slideview");

		onMounted(() => {
			if (isSlideView.value) {
				contentsElement.value.style.pointerEvents = "all";
				contentsElement.value.setAttribute("swipeable", false);
			}
		});

		//
		// viewmode calendar

		const isCalendarView = computed(() => props.attributes.viewMode === "calendarview");

		// autoslide
		let autoslideInterval;
		let currentSlideIndex = 0;

		onMounted(() => {
			if (isSlideView.value) {
				watch(
					() => loadedRowsLength.value,
					async (newValue) => {
						if (newValue !== contents.value?.length) return;
						await nextTick();

						if (props.attributes.autoslideDelay) {
							contentsElement.value.scrollTo(0, 0);
							// create interval
							autoslideInterval = setInterval(() => {
								if (contentsLoaderHelper.getRowsLength() === 0) return;

								currentSlideIndex += 1;
								if (currentSlideIndex >= contentsLoaderHelper.getRowsLength()) currentSlideIndex = 0;
								/**
								 * Get element
								 * @type {HTMLElement}
								 */
								const element = document.getElementById(`${props.attributes.name.replace("@", "")}${currentSlideIndex}${breadcrumbId}`);
								if (!element) return;

								// Scroll to element
								contentsElement.value.scrollTo({
									left: element.offsetLeft,
									top: 0,
									behavior: "smooth",
								});
							}, props.attributes.autoslideDelay * 1000);
						} else contentsElement.value.scrollTo(0, 0);
					}
				);
			}
		});

		// clear autoslide interval
		onUnmounted(() => {
			if (autoslideInterval) clearInterval(autoslideInterval);
		});

		//
		// viewmode chart
		const isChartView = computed(() => props.attributes.viewMode && props.attributes.viewMode.toString().contains("chart"));

		const isChartBar = computed(
			() =>
				props.attributes.viewMode === "barchart" ||
				props.attributes.viewMode === "3dbarchart" ||
				props.attributes.viewMode === "stackedbarchart" ||
				props.attributes.viewMode === "linechart"
		);

		const isChartPie = computed(() => props.attributes.viewMode === "piechart" || props.attributes.viewMode === "piechart2");

		//
		// viewmode picturemap

		const isPictureMapView = computed(() => props.attributes.viewMode === "picturemap");

		//
		// viewmode Expan

		const isExpanView = computed(() => props.attributes.viewMode === "expanview");

		// expan item selected
		provide("onExpanItemSelected", (rowInfo) => {
			if (rowInfo.isExpanded) return (rowInfo.isExpanded = false);
			contentsLoaderHelper.getContentsRowsInfo().value.forEach((e) => (e.isExpanded = false));
			rowInfo.isExpanded = true;
		});

		//
		// viewmode router-view

		const isRouterView = computed(() => props.attributes.viewMode === "routerview" || props.attributes.viewMode === "stacknavigation");

		provide("containerSize", {
			containerWidth: props.attributes.viewMode === "routerview" ? computed(() => props.controlWidth - 2) : containerWidth,
			containerHeight: props.attributes.viewMode === "routerview" ? computed(() => props.controlHeight - 2) : containerHeight,
		});

		if (isRouterView.value) {
			//
			// Scale Factor

			/** @type {{appWidth: Ref<number>, appHeight:Ref<number>}} */
			const { appWidth, appHeight } = inject("appSize");

			/**
			 * widthFactor
			 * @type {ComputedRef<number>}
			 */
			const widthFactor = computed(() => (appWidth.value ? props.controlWidth / appWidth.value : 1));

			/**
			 * heightFactor
			 * @type {ComputedRef<number>}
			 */
			const heightFactor = computed(() => (appHeight.value ? props.controlHeight / appHeight.value : 1));

			// provide scaleFactor to child components
			provide("scaleFactor", { widthFactor, heightFactor });
		}

		if (isGridView.value || isSlideView.value) contentsLoaderHelper.rowsPerPage = 100;

		//
		// onScroll event
		let scrollTimeout;
		const onScroll = () => {
			if (scrollTimeout) clearTimeout(scrollTimeout);
			scrollTimeout = setTimeout(() => xoneAttributesHandler.onScrollEvent(contentsElement.value, props.attributes, props.xoneDataObject), 250);
		};
		nextTick(() => onScroll());

		return {
			isContents,
			contentsElement,
			fitHeight,
			contentsRowsInfo: contentsLoaderHelper.getContentsRowsInfo(),
			isLoading,
			breadcrumbId,
			isGridView,
			gridTemplateColumns,
			isMapView,
			isSlideView,
			isCalendarView,
			isChartView,
			isChartBar,
			isChartPie,
			isPictureMapView,
			isExpanView,
			isRouterView,
			onScroll,
			rowsLength: computed(() => contentsLoaderHelper.getRowsLength()),
		};
	},
};
</script>

<style scoped>
.xone-contents {
	position: relative;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	width: calc(100%);
	height: 100%;
	/* overflow-x: visible; */
	overflow-x: hidden;
	overflow-y: auto;
	/* transition: all 0.1s; */
	animation: fadeIn 0.2s;

	max-height: var(--contents-max-height);
	z-index: 1;
}
.xone-contents div {
	scroll-snap-align: start;
}

.xone-gridview {
	display: grid;
}

.xone-slideview {
	display: flex;
	overflow-x: auto;
	overflow-y: visible;
	scroll-snap-type: x mandatory;
}
</style>
